import React, { useCallback, useRef, useState } from 'react';
import {
  Grid,
  ListSubheader,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Placeholder from '../../../assets/images/placeholderImage.png';
import MenuItem from '@mui/material/MenuItem';
import {
  PRODUCT_DELIVERY_OPTIONS,
  SERVICE_DELIVERY_OPTIONS,
  PRODUCT_CONDITIONS,
  SERVICE_CONDITIONS,
  ALL_CATEGORIES,
} from '../../common/constants';
import SecondaryGrayButton from '../../common/SecondaryGrayButton';
import ImageCrop from '../../common/ImageCropModal/ImageCropModal';
import useFetchAssetDetails from '../hooks/useFetchAssetDetails';
import useGetCategories from '../../common/hooks/useGetCategories';
import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { THEME_COLORS } from '../../common/colors';
import IconButton from '@mui/material/IconButton';
import MultipleSelect from '../../common/Form/MultipleSelect';
import PrimaryButton from '../../common/PrimaryButton';
import ImageIcon from '@mui/icons-material/PhotoCamera';
import { ErrorFieldMessage } from '../../common/ErrorFieldMessage';
import useUploadAssetImages from '../hooks/useUploadAssetImages';
import { readFile } from '../../common/utils';
import { TextFormField } from '../../common/Form/TextFormField';
import { SelectField } from '../../common/Form/SelectField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ImageCarousel } from '../../common/ImageCarousel';
import MultipleAutocompleteSelect from '../../common/Form/MultipleAutocompleteSelect';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const ACCEPTED_IMAGE_VIDEO_FORMATS =
  '.jpg, .jpeg, .png, .gif, .bmp, .tiff, .webp, .mp4, .mov, .wmv, .avi, .flv, .mkv';

const ButtonContainer = styled(DialogActions)`
  display: flex !important;
  position: relative !important;
  ${({ isCreating }) =>
    isCreating && 'justify-content: space-between !important;'}
  width: ${({ isMobile }) => (isMobile ? 90 : 60)}% !important;
  margin: 0 auto !important;
  margin-bottom: 12px !important;
`;

const FormField = styled(TextFormField)`
  .MuiInputBase-root {
    margin-bottom: 0.1rem !important;
  }
  .Mui-error {
    margin-left: 0 !important;
  }
  .MuiInputBase-input {
    padding: 4px !important;
  }
`;

const TitleTextFormField = styledMUI(TextField)(({ isMobile }) => ({
  width: '100%',
  margin: isMobile ? '1.5rem 0 !important' : 0,
  '.MuiInput-root::before': {
    border: 'unset !important',
  },
  '.MuiInput-root:hover::before': {
    border: 'unset !important',
  },
  '.MuiInput-root:focus::after': {
    border: 'unset !important',
  },
  '.MuiInputBase-input': {
    fontSize: '1.5rem !important',
    fontWeight: 'bold !important',
    paddingTop: 0,
    height: 14,
  },
}));

const ConfirmModalClose = styled(Snackbar)`
  position: absolute !important;
  bottom: 45px !important;
  left: 6px !important;

  .MuiSnackbarContent-root {
    background-color: #f4f5f4 !important;
    box-shadow: unset !important;
    border: unset !important;
    width: unset !important;
  }
`;

const DropdownGroupLabel = styled(ListSubheader)`
  line-height: 24px !important;
  font-style: italic !important;
  font-weight: 700 !important;
`;

const ProTip = styled(Typography)`
  color: ${THEME_COLORS.primary} !important;
  font-size: 0.85rem !important;
`;

const EditImageIconContainer = styled.label`
  position: absolute;
  right: 8px;
  top: 0;
`;

const EditImageIcon = styled(IconButton)`
  position: absolute !important;
  right: 0 !important;
  top: 4px !important;
  background-color: #ffffff70 !important;
`;

const EditablePhotos = styled.div`
  position: relative;
  margin: 0 auto;
  overflow-y: hidden;
  height: 220px;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const FormMultiSelect = styled(MultipleSelect)`
  margin-bottom: 16px !important;
  .MuiSelect-select {
    padding: 4px !important;
  }
`;

const SplitGridFields = styled(Grid)`
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
`;

const ImageContainer = styled(Grid)`
  max-width: 220px !important;
  margin: 0 auto !important;
  margin-bottom: 12px !important;
`;

const FieldHeader = styled(Typography).attrs({
  component: 'h3',
})`
  margin: 0.4rem 0 !important;
`;

const TextAreaLimit = styled.div`
  &,
  span {
    color: ${THEME_COLORS.primary} !important;
  }
  text-align: right;
  font-size: 0.8rem;
`;

function EditImageButton({ fileInputRef, onClick }) {
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <EditImageIconContainer htmlFor="fileInput" onClick={handleIconClick}>
      <EditImageIcon>
        <ImageIcon />
      </EditImageIcon>
      <input
        type="file"
        id="fileInput"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={onClick}
        accept={ACCEPTED_IMAGE_VIDEO_FORMATS}
      />
    </EditImageIconContainer>
  );
}

export function AssetForm({
  uuid,
  onClose,
  updateAsset,
  createAsset,
  errors,
  updating,
  isMobile,
}) {
  const fileInputRef = useRef(null);

  const { asset, setAsset } = useFetchAssetDetails(uuid);
  const { categories } = useGetCategories();
  const [openModal, setOpenModal] = useState(null);
  const [imageToSave, setImageToSave] = useState(null);
  const [otherAssetValue, setOtherAssetValue] = useState({
    label: '',
    value: '',
  });
  const [otherCategories] = useState({ label: '', value: '' });
  const [imageIndex, setImageIndex] = useState(0);
  const [isEdited, setIsEdited] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  const { loading: loadingImage, uploadFinalImage } = useUploadAssetImages(
    asset,
    setAsset,
    setOpenModal,
    imageIndex,
  );

  const handleFileUpload = async event => {
    if (event.target.files[0]) {
      const imageDataUrl = await readFile(event.target.files[0]);
      setOpenModal('UPLOAD_ASSET_IMAGE');
      setImageToSave(imageDataUrl);
    }
  };

  const onFieldChange = formData => {
    setIsEdited(true);
    setAsset({
      ...asset,
      ...formData,
    });
  };

  const onSubmit = useCallback(
    closeOnSave => {
      let updatedAsset = asset;
      let newCategories = [];
      if (otherAssetValue.label && asset.category === 'OTHER') {
        updatedAsset = { ...updatedAsset, category: otherAssetValue.value };
        newCategories.push(otherAssetValue);
      }

      if (otherCategories.label && asset.categories.includes('OTHER')) {
        updatedAsset = {
          ...updatedAsset,
          categories: [
            ...updatedAsset.categories.filter(v => v !== 'OTHER'),
            otherCategories.value,
          ],
        };
        newCategories.push(otherCategories);
      }

      if (asset.id) {
        updateAsset(
          { ...updatedAsset, newCategories },
          setAsset,
          closeOnSave,
          !closeOnSave,
        );
      } else {
        createAsset({ ...updatedAsset, newCategories }, setAsset, closeOnSave);
      }
    },
    [asset, updateAsset, createAsset, otherAssetValue, otherCategories],
  );

  const onValueChange = useCallback(
    field => value => {
      onFieldChange({ [field]: value });
    },
    [asset, onFieldChange],
  );

  const onEventChange = useCallback(
    field => e => {
      const value = e.target.value;
      onValueChange(field)(value);
    },
    [asset, onValueChange],
  );

  const assetImages = Array.from(
    { length: 5 },
    (v, i) => asset.images[i] ?? null,
  );

  return (
    <div style={{ marginTop: '0.5rem' }}>
      <DialogContent>
        <Grid
          container
          justifyContent="space-between"
          sx={{ marginBottom: '1rem !important' }}
        >
          <ImageContainer item xs={12} sm={12} lg={5}>
            <ImageCarousel
              autoPlay={false}
              fullHeightHover={false}
              navButtonsAlwaysVisible={true}
              indicatorContainerProps={{
                style: {
                  position: 'absolute',
                  zIndex: 99,
                  bottom: '6px',
                },
              }}
              onChange={now => {
                setImageIndex(now);
              }}
              animation="slide"
            >
              {assetImages.map((image, i) => (
                <EditablePhotos
                  key={`asset-image-${image ?? 'placeholder'}-${i}`}
                >
                  <img src={image ?? Placeholder} alt="asset-image" />
                  <Backdrop
                    sx={{
                      position: 'absolute',
                      zIndex: theme => theme.zIndex.drawer + 1,
                    }}
                    open={loadingImage}
                  >
                    <CircularProgress color="secondary" />
                  </Backdrop>
                  <EditImageButton
                    onClick={handleFileUpload}
                    fileInputRef={fileInputRef}
                    size="large"
                  />
                </EditablePhotos>
              ))}
            </ImageCarousel>
            <ErrorFieldMessage
              offsetMargin={false}
              message={errors.mainImage}
              isVisible={errors.mainImage}
            />
          </ImageContainer>
          <SplitGridFields item xs={12} sm={12} lg={6.7}>
            <div>
              <TitleTextFormField
                isMobile={isMobile}
                color="warning"
                variant="standard"
                error={!!errors.title}
                helperText={errors.title}
                value={asset.title}
                placeholder="New asset's title*"
                onChange={onEventChange('title')}
              />
              <ProTip>
                Pro tip: Add 3 assets or more for better matching.
              </ProTip>
            </div>
            <div>
              <FieldHeader>Category*</FieldHeader>
              <SelectField
                id="filled-select-category"
                select
                value={asset.category}
                error={!!errors.category}
                helperText={errors.category}
                variant="filled"
                color="warning"
                style={{ width: '100%' }}
                onChange={e =>
                  onFieldChange({
                    category: e.target.value,
                    categories: asset.categories.filter(
                      c => c !== e.target.value,
                    ),
                  })
                }
              >
                {ALL_CATEGORIES.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SelectField>
              {asset.category === 'OTHER' && (
                <div>
                  <FieldHeader>Category Name*</FieldHeader>
                  <FormField
                    variant="filled"
                    color="warning"
                    error={!!errors.category}
                    helperText={errors.category}
                    value={otherAssetValue.label}
                    onChange={e => {
                      const label = e.target.value;
                      setOtherAssetValue({
                        label,
                        value: label
                          .split(' ')
                          .map(word => word.toUpperCase())
                          .join('_'),
                      });
                    }}
                  />
                </div>
              )}
              <FieldHeader>Condition*</FieldHeader>
              <SelectField
                id="filled-select-delivery-type"
                select
                defaultValue="localPickup"
                variant="filled"
                color="warning"
                style={{ width: '100%' }}
                error={!!errors.condition}
                helperText={errors.condition}
                value={`${asset.condition}.${asset.type}`}
                onChange={e => {
                  const [value, type] = e.target.value.split('.');
                  onFieldChange({
                    type,
                    condition: value,
                  });
                }}
              >
                <DropdownGroupLabel>Product</DropdownGroupLabel>
                {PRODUCT_CONDITIONS.map(option => (
                  <MenuItem
                    key={`${option.value}.${option.type}`}
                    value={`${option.value}.${option.type}`}
                  >
                    {option.label}
                  </MenuItem>
                ))}
                <DropdownGroupLabel>Service</DropdownGroupLabel>
                {SERVICE_CONDITIONS.map(option => (
                  <MenuItem
                    key={`${option.value}.${option.type}`}
                    value={`${option.value}.${option.type}`}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </SelectField>
              <FieldHeader>Delivery methods*</FieldHeader>
              <FormMultiSelect
                id="filled-select-subcategories"
                variant="filled"
                color="warning"
                values={asset.deliveryMethods}
                style={{ width: '100%' }}
                options={[
                  ...PRODUCT_DELIVERY_OPTIONS,
                  ...SERVICE_DELIVERY_OPTIONS,
                ]}
                onChange={onEventChange('deliveryMethods')}
              />
              <ErrorFieldMessage
                offsetMargin={false}
                message={errors.deliveryMethods}
                isVisible={errors.deliveryMethods}
              />
              {false && (
                <SelectField
                  id="filled-select-delivery-type"
                  select
                  defaultValue="localPickup"
                  variant="filled"
                  color="warning"
                  style={{ width: '100%' }}
                  error={!!errors.deliveryMethods}
                  helperText={errors.deliveryMethods}
                  value={asset.deliveryMethod}
                  onChange={onEventChange('deliveryMethod')}
                >
                  <DropdownGroupLabel>Product</DropdownGroupLabel>
                  {PRODUCT_DELIVERY_OPTIONS.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                  <DropdownGroupLabel>Service</DropdownGroupLabel>
                  {SERVICE_DELIVERY_OPTIONS.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </SelectField>
              )}
            </div>
          </SplitGridFields>
        </Grid>
        <FieldHeader>Description*</FieldHeader>
        <FormField
          id="field-description"
          multiline
          error={!!errors.description}
          helperText={errors.description}
          value={asset.description}
          color="warning"
          placeholder="Describe your assets..."
          rows={2}
          variant="filled"
          onChange={onEventChange('description')}
        />
        <TextAreaLimit>800 characters or less</TextAreaLimit>
        <FieldHeader>Tags</FieldHeader>
        {false && (
          <MultipleAutocompleteSelect
            id="filled-select-subcategories-autocomplete"
            variant="filled"
            color="warning"
            values={asset.categories}
            style={{ width: '100%' }}
            options={categories.filter(c => c.value !== asset.category)}
            onChange={onValueChange('categories')}
          />
        )}
        <FormMultiSelect
          id="filled-select-subcategories"
          variant="filled"
          color="warning"
          values={asset.categories}
          style={{ width: '100%' }}
          prefix="#"
          options={categories.filter(c => c.value !== asset.category)}
          onChange={onEventChange('categories')}
        />
      </DialogContent>
      <ButtonContainer isMobile={isMobile} isCreating={!!createAsset}>
        {createAsset && (
          <SecondaryGrayButton
            onClick={() =>
              isEdited && !confirmClose ? setConfirmClose(true) : onClose()
            }
            loading={updating}
          >
            {confirmClose ? 'Close' : 'Done'}
          </SecondaryGrayButton>
        )}
        {createAsset && (
          <PrimaryButton onClick={() => onSubmit(false)} loading={updating}>
            Add another
          </PrimaryButton>
        )}
        {!createAsset && updateAsset && (
          <PrimaryButton onClick={() => onSubmit(true)} loading={updating}>
            Done
          </PrimaryButton>
        )}
        <ConfirmModalClose
          open={confirmClose}
          message="Are you okay with losing your changes?"
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setConfirmClose(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </ButtonContainer>
      {openModal === 'UPLOAD_ASSET_IMAGE' && (
        <ImageCrop
          onClose={() => setOpenModal(null)}
          isMobile={isMobile}
          loading={loadingImage}
          setShowModal={setOpenModal}
          imageToSave={imageToSave}
          fileInputRef={fileInputRef}
          setImage={setImageToSave}
          filename={`asset-${asset.id}-image-${Date().toString()}`}
          sendCroppedImageServer={uploadFinalImage}
        />
      )}
    </div>
  );
}
