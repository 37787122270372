/* eslint-disable react/jsx-props-no-spreading */
import React, {useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from './AuthContext';
import { isTokenExpired } from '../auth/AuthContainer';
import { useHistory } from "react-router-dom";
import { useGetFormat } from "./useGetFormat";
import ReactGA from "react-ga4";

const NotLoggedRoute = ({ component: Component, pageTitle, ...rest }) => {
  const history = useHistory();
  const format = useGetFormat();
  const { authContext } = useContext(AuthContext);
  const defaultValue =
    authContext.token !== null && !isTokenExpired(authContext.token);
  const [secureAuth, setSecureAuth] = useState(defaultValue);
  const [queryDefaultParams] = useState(new URLSearchParams(window.location?.search));
  if (secureAuth === true) {
    authContext.userData.then(data => setSecureAuth(data.success));
    history.push('/search/?message=already_loggedin');
  }

  useEffect(() => {
    if(window.location?.search && window.location?.search.includes('message')) {
      history.push(window.location.pathname);
    }
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: pageTitle });
  }, [window.location.pathname]);

  return <Component history={history} format={format} queryDefaultParams={queryDefaultParams} {...rest} />;
};

NotLoggedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default NotLoggedRoute;
