import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import Chip from "@mui/material/Chip";
import styled from "styled-components";
import {THEME_COLORS} from "../../common/colors";

const AUTO_SAVE_INFO = 'Autosaved as draft';
const AUTO_SAVE_DELETE = 'Clear autosaved draft';

function saveProfileDraft(user) {
    localStorage.setItem(`draft-profile-${user.uuid}`, JSON.stringify(user));
}

const AutoSaveTag = styled(Chip)`
    &:hover {
        background-color: ${THEME_COLORS.primary};
        .MuiChip-label {
            color: #fff !important;
        }
    }
`;

export function SaveDraftStatus({ user, draftTimestamp, setDraftTimestamp, isMobile }) {
    const userData = localStorage.getItem(`draft-profile-${user.uuid}`);
    const [label, setLabel] = useState(isMobile ? AUTO_SAVE_DELETE: AUTO_SAVE_INFO);

    useEffect(() => {
        saveProfileDraft(user);
        setDraftTimestamp(moment().format('MMM DD hh:mma'))
    }, [user]);

    const handleDelete = () => {
        localStorage.removeItem(`draft-profile-${user.uuid}`);
        window.location.reload();
    };

    useEffect(() => {
        if(!userData) {
            setDraftTimestamp(null);
        }
    }, [userData]);



    if (userData && draftTimestamp) {
        return (
            <AutoSaveTag
                label={label}
                onMouseEnter={() => setLabel(AUTO_SAVE_DELETE)}
                onMouseLeave={() => setLabel(AUTO_SAVE_INFO)}
                onClick={handleDelete}
            />
        )
    }

    return null;
}