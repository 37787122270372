import styled from 'styled-components';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../common/PrimaryButton';
import useContactSuapMember from './useContactSuapMembers';
import { useHistory } from 'react-router-dom';
import { TextFormField } from '../common/Form/TextFormField';

const MiddlePanel = styled.div`
  grid-area: MiddlePanel;
  padding: 0 7%;
  padding-bottom: ${({ ismobile }) => (ismobile === 'true' ? 12 : 100)}px;
`;

const SubmitButton = styled(PrimaryButton)`
  display: flex;
  margin: 1rem auto !important;
  margin-bottom: 50px !important;
`;

const FormLabel = styled(Typography)`
  margin-top: 1rem;
`;

const FormSection = styled(Grid)`
  margin-bottom: 1rem !important;
`;

export function MiddleSection({ user, isMobile }) {
  const history = useHistory();

  const [contactUsForm, setContactUsForm] = useState({
    firstname: '',
    surname: '',
    email: '',
    message: '',
  });

  useEffect(() => {
    if (user.firstname && !contactUsForm.firstname) {
      setContactUsForm({
        firstname: user.firstname,
        surname: user.surname,
        email: user.email,
        message: '',
      });
    }
  }, [user]);

  const { sendMessage, isLoading } = useContactSuapMember(
    history,
    setContactUsForm,
  );
  const handleFieldsChange = values => {
    setContactUsForm({
      ...contactUsForm,
      ...values,
    });
  };

  const handleFieldChange = field => e => {
    handleFieldsChange({ [field]: e.target.value });
  };

  return (
    <MiddlePanel ismobile={isMobile?.toString()}>
      <FormSection container spacing={2}>
        <Grid item xs={12} sm={6} lg={6}>
          <FormLabel variant="body1" gutterBottom>
            First name*
          </FormLabel>
          <TextFormField
            color="warning"
            id="form-firstname-number"
            variant="filled"
            onChange={handleFieldChange('firstname')}
            value={contactUsForm.firstname}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <FormLabel variant="body1" gutterBottom>
            Last name*
          </FormLabel>
          <TextFormField
            color="warning"
            id="form-surname-number"
            variant="filled"
            onChange={handleFieldChange('surname')}
            value={contactUsForm.surname}
          />
        </Grid>
      </FormSection>
      <FormSection container justifyContent="space-between">
        <Grid item xs={12} sm={12} lg={12}>
          <FormLabel variant="body1" gutterBottom>
            Email*
          </FormLabel>
          <TextFormField
            color="warning"
            id="form-email"
            variant="filled"
            onChange={handleFieldChange('email')}
            value={contactUsForm.email}
          />
        </Grid>
      </FormSection>
      <FormLabel variant="body1" gutterBottom>
        Message*
      </FormLabel>
      <TextFormField
        id="outlined-multiline-flexible"
        multiline
        color="warning"
        placeholder="How can we help you?"
        rows={10}
        onChange={handleFieldChange('message')}
        value={contactUsForm.message}
        variant="filled"
      />
      <div style={{ width: '100%' }}>
        <SubmitButton
          onClick={() => sendMessage(contactUsForm)}
          disabled={isLoading}
        >
          Send
        </SubmitButton>
      </div>
    </MiddlePanel>
  );
}
