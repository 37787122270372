import { Box, Button, Typography, Tooltip } from '@mui/material';
import LikableIcon from '@mui/icons-material/FavoriteTwoTone';
import LikedIcon from '@mui/icons-material/Favorite';
import React, { useState } from 'react';
import styled from 'styled-components';
import { THEME_COLORS } from '../../common/colors';
import IconButton from '@mui/material/IconButton';
import useCreateChat from '../hooks/useCreateChat';
import useFetchReviews from '../../review/useFetchReviews';
import { Ratings } from '../../common/RatingDisplays';

const AssetTitle = styled(Typography)`
  font-weight: bold !important;
  font-size: 1rem !important;
  display: block !important;
  height: 38px !important;
  overflow-y: hidden !important;
`;

const RatingCount = styled(Typography)`
  display: inline-block;
  font-size: 0.775rem;
`;

const SuapButton = styled(Button)`
  background-color: ${THEME_COLORS.primary} !important;
  box-shadow: unset !important;
  border-radius: ${({ isMobile }) => (isMobile ? 4 : 12)}px !important;
  font-weight: 800 !important;
  font-size: 0.83rem;
  padding: ${({ isMobile }) => (isMobile ? 4 : 2)}px 10px !important;
  text-transform: capitalize !important;
  min-width: ${({ isMobile }) => (isMobile ? '100%' : '85px')} !important;
`;

const AssetSuapContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ isMobile }) => isMobile && 'justify-content: center;'};
  height: 35px;
  margin-top: 8px;

  p {
    font-size: 0.725rem;
  }
`;

const AssetCardContainer = styled.div`
  width: ${({ isMobile }) => (isMobile ? 250 : 150)}px;

  @media screen and (min-width: 375px) {
    width: 165px;
  }

  @media screen and (min-width: 440px) {
    width: 200px;
  }

  @media screen and (min-width: 768px) {
    width: 238px;
  }

  @media screen and (min-width: 1024px) {
    width: 220px;
  }

  @media screen and (min-width: 1440px) {
    width: 200px;
  }

  padding-top: 0;
  padding-bottom: 12px;
  border: 1px solid #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;

  img {
    width: 100%;
    height: auto;
    cursor: pointer;
  }
`;

const AssetCardDetails = styled.div`
  height: 94px;
  display: flex;
  padding: 0 6px;
  flex-direction: column;
  justify-content: space-between;
`;

const LikableContainer = styled.div`
  position: relative;
  background-color: #f7f7f7;
  padding: 0 6px;
  margin-bottom: 4px;
`;

const LikeButtonContainer = styled(IconButton)`
  position: absolute;
  right: -16px;
  top: -16px;

  svg {
    fill: #ff6a6b !important;
    ${({ $isLiked }) =>
      !$isLiked &&
      `
        path:first-child {
          opacity: 1;
          fill: white;
        }
    `}
  }
`;

function LikableButton({ onClick, isLiked }) {
  const [likeToggle, setLikeToggle] = useState(!!isLiked);
  return (
    <LikeButtonContainer
      aria-label="like-button"
      onClick={() => setLikeToggle(!likeToggle)}
      $isLiked={likeToggle}
    >
      {likeToggle ? <LikedIcon /> : <LikableIcon />}
    </LikeButtonContainer>
  );
}

function TruncateTitle({ title }) {
  if (title.length > 52) {
    return <Tooltip title={title}>{title.slice(0, 52)}...</Tooltip>;
  }

  return <span>{title}</span>;
}

export default function AssetCard({ isMobile, userInfo, asset, history }) {
  const { initiateSuap, loading } = useCreateChat(history);

  const { reviews } = useFetchReviews('asset', asset?.id);

  const handleSuap = () => {
    if (asset.serialId) {
      history.push(`/chat/${asset.serialId}`);
    } else {
      const predefinedMessage = '[PREDEFINED]Say hi and edit your offer above';
      initiateSuap({
        predefinedMessage,
        ownerId: asset.user.id,
        assetId: asset.id,
      });
    }
  };

  const redirectToAsset = () => {
    window.location = '/suap-wallet?message=add_assets_to_suap';
  };

  const suapMethod =
    userInfo.activeassetcount > 0 ? handleSuap : redirectToAsset;

  return (
    <AssetCardContainer isMobile={isMobile}>
      <LikableContainer>
        {false && <LikableButton />}
        <img
          alt={asset.title}
          src={asset.mainImage}
          onClick={() => window.open(`/assets/${asset.uuid}`, '_blank')}
          style={{ cursor: 'pointer' }}
        />
      </LikableContainer>
      <AssetCardDetails>
        <div>
          <AssetTitle
            onClick={() => history.push(`/assets/${asset.uuid}`)}
            style={{ cursor: 'pointer' }}
          >
            <TruncateTitle title={asset.title} />
          </AssetTitle>
          {!!reviews.assetReviewAverage && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                margin: '4px 0 8px 0',
              }}
            >
              <Ratings
                name="read-only"
                readOnly
                size="small"
                value={reviews.assetReviewAverage ?? 0}
              />
              <RatingCount variant="body2">
                ({reviews.assetReviewTotal})
              </RatingCount>
            </Box>
          )}
        </div>
        <AssetSuapContainer isMobile={isMobile}>
          {false && (
            <div>
              <Typography variant="body2">Value</Typography>
              <Typography variant="body2" fontWeight="bold">
                $ {asset.estimatedCost}
              </Typography>
            </div>
          )}
          {userInfo?.id !== asset.userId && (
            <SuapButton
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              onClick={suapMethod}
              disabled={loading}
              isMobile={isMobile}
            >
              Suap
            </SuapButton>
          )}
        </AssetSuapContainer>
      </AssetCardDetails>
    </AssetCardContainer>
  );
}
