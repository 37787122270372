import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import DescriptiveRating from './DescriptiveRating';
import styled from 'styled-components';
import Placeholder from '../../assets/images/placeholderImage.png';
import DialogForm from '../common/DialogForm';
import useEditReview from './useEditReview';
import useFetchReviews from './useFetchReviews';
import { Body10, Header12, Header18 } from '../common/Typography';
import { THEME_COLORS } from '../common/colors';
import DialogContent from '@mui/material/DialogContent';
import { TextFormField } from '../common/Form/TextFormField';
import { styled as styledMUI } from '@mui/material/styles';
import FORMAT_SIZES from '../common/constants/FormatSizes';
import { ReactSVG } from 'react-svg';
import Arrow from '../../assets/arrow.svg';

const AssetContainer = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? 0 : 12)}px;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  margin: 0 auto;
  margin-bottom: 1rem;
  max-width: 400px;
`;

const TermsTitle = styled(Header18)`
  text-align: center !important;
`;

const AssetPanel = styledMUI(Box)(({ isMobile }) => ({
  flexDirection: `${isMobile ? 'row' : 'column'} !important`,
  gap: `${isMobile ? 12 : 0}px !important`,
  textAlign: `${isMobile ? 'left' : 'center'} !important`,
  ...(isMobile ? { width: '100%', maxWidth: '250px' } : { maxWidth: '35%' }),
  justifyContent: 'center !important',
  alignItems: 'center !important',
}));

const SuapIcon = styled(ReactSVG)`
  svg {
    height: ${({ isMobile }) => (isMobile ? 30 : 50)}px !important;
  }
  display: block !important;
  fill: ${THEME_COLORS.primary}!important;
  ${({ flipped }) =>
    flipped &&
    `
        transform: scale(-1, -1) !important;
  `}
`;

const AssetMainImage = styled(Avatar)`
  width: ${({ isMobile }) => (isMobile ? 50 : 100)}% !important;
  height: auto !important;
  margin-bottom: 0.7rem !important;
`;

const RatingSection = styled.div`
  max-width: 355px;
  margin: 0 auto;
`;
const RatingContainer = styled.div`
  margin-bottom: 12px;
`;

const FormTextField = styled(TextFormField)`
  .MuiInputBase-root {
    margin-bottom: 0.1rem !important;
  }
  .Mui-error {
    margin-left: 0 !important;
  }
  .MuiInputBase-input {
    padding: 4px !important;
  }
`;
function Form({
  contractId,
  reviewId,
  currentUser,
  otherUser,
  contractUser,
  otherContractUser,
  isMobile,
  reviewForm,
  setReviewForm,
  format,
}) {
  useEffect(() => {
    if (otherContractUser.asset?.id) {
      setReviewForm({
        id: reviewId,
        userId: otherUser.id,
        assetId: otherContractUser.asset?.id,
        contractId,
        userRating: 0,
        assetRating: 0,
        reviewText: '',
      });
    }
  }, [otherContractUser]);

  const initializeReviewForm = useCallback(
    data => {
      if (data.reviews.length > 0) {
        setReviewForm({
          ...reviewForm,
          id: data.reviews[0].id,
          userRating: data.reviews[0].userRating,
          assetRating: data.reviews[0].assetRating,
          reviewText: data.reviews[0].reviewText,
        });
      }
    },
    [reviewForm],
  );

  const { isLoading: dataLoading } = useFetchReviews(
    'contract',
    contractId,
    initializeReviewForm,
  );
  const onFieldChange = formData => {
    setReviewForm({
      ...reviewForm,
      ...formData,
    });
  };

  const onEventChange = useCallback(
    field => e => {
      const value = e.target.value;
      onFieldChange({ [field]: value });
    },
    [onFieldChange],
  );

  return (
    <DialogContent>
      <TermsTitle>WeSuap Review</TermsTitle>
      <AssetContainer isMobile={format === FORMAT_SIZES.MOBILE}>
        <AssetPanel
          sx={{ display: { xs: 'flex', sm: 'inline-flex' } }}
          isMobile={format === FORMAT_SIZES.MOBILE}
        >
          <AssetMainImage
            variant="round"
            isMobile={isMobile}
            src={otherContractUser.asset?.mainImage || Placeholder}
          />
          <div>
            <Header12>
              {otherUser.firstName} {otherUser.lastName}
            </Header12>
            <Body10>
              {otherContractUser.asset?.title ?? 'Waiting for asset'}
            </Body10>
          </div>
        </AssetPanel>
        <AssetPanel>
          <SuapIcon isMobile={isMobile} src={Arrow} />
          <SuapIcon flipped={true} isMobile={isMobile} src={Arrow} />
        </AssetPanel>
        <AssetPanel
          sx={{ display: { xs: 'flex', sm: 'inline-flex' } }}
          isMobile={format === FORMAT_SIZES.MOBILE}
        >
          <AssetMainImage
            variant="round"
            isMobile={isMobile}
            src={contractUser.asset?.mainImage || Placeholder}
          />
          <div>
            <Header12>
              {currentUser.firstName} {currentUser.lastName}
            </Header12>
            <Body10>My Asset</Body10>
          </div>
        </AssetPanel>
      </AssetContainer>
      <RatingSection>
        <RatingContainer>
          <Typography variant="body1" gutterBottom>
            How was the interaction with {otherUser.firstName}?*
          </Typography>
          <DescriptiveRating
            disabled={dataLoading}
            id="userRating"
            value={reviewForm.userRating}
            onChange={onEventChange('userRating')}
            size="large"
          />
        </RatingContainer>
        <RatingContainer>
          <Typography variant="body1" gutterBottom>
            What is your rating?*
          </Typography>
          <DescriptiveRating
            id="assetRating"
            disabled={dataLoading}
            value={reviewForm.assetRating}
            onChange={onEventChange('assetRating')}
            size="large"
          />
        </RatingContainer>
        <RatingContainer>
          <Typography variant="body1" gutterBottom>
            Leave a review
          </Typography>
          <FormTextField
            id="field-review-description"
            disabled={dataLoading}
            multiline
            color="warning"
            rows={3}
            variant="filled"
            value={reviewForm.reviewText}
            onChange={onEventChange('reviewText')}
          />
        </RatingContainer>
      </RatingSection>
    </DialogContent>
  );
}
export default function ReviewFormModal({
  contractId,
  currentUser = {},
  otherUser = {},
  contractUser = {},
  otherContractUser = {},
  reviewId,
  setOpenReviewModal,
  onReviewSubmission,
  isMobile,
  isOpen,
  format,
}) {
  const [reviewForm, setReviewForm] = useState({
    id: reviewId,
    userId: otherUser.id,
    assetId: otherContractUser.asset?.id,
    contractId,
    userRating: 0,
    assetRating: 0,
    reviewText: '',
  });

  const { createReview, updateReview, isLoading: updating } = useEditReview(
    onReviewSubmission,
    currentUser,
  );

  const onSubmit = useCallback(() => {
    if (reviewForm.id) {
      updateReview(reviewForm);
    } else {
      createReview(reviewForm);
    }
    setOpenReviewModal(null);
  }, [reviewForm]);

  return (
    <DialogForm
      label=""
      description=""
      buttonLabel="Submit"
      onSave={onSubmit}
      disabled={updating}
      hideCloseButton={true}
      onClose={() => setOpenReviewModal(null)}
      isMobile={isMobile}
      isOpen={isOpen}
    >
      {otherContractUser.asset?.id ? (
        <Form
          reviewId={reviewId}
          contractId={contractId}
          currentUser={currentUser}
          otherUser={otherUser}
          contractUser={contractUser}
          otherContractUser={otherContractUser}
          isMobile={isMobile}
          isOpen={isOpen}
          reviewForm={reviewForm}
          setReviewForm={setReviewForm}
          format={format}
        />
      ) : null}
    </DialogForm>
  );
}
