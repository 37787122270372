import React, { useState } from 'react';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { ProfilePicture, Tag, TruncateButton } from '../styledComponents';
import Placeholder from '../../../assets/images/placeholderImage.png';
import LogoutButton from '../components/LogoutButton';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ErrorFieldMessage } from '../../common/ErrorFieldMessage';
import { Editable, SubHeader, TagDisplay } from './styles';
import { EditButton } from './EditButton';
import { Typography } from '@mui/material';
import { EditImageButton } from '../components/EditImageButton';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Header12 } from '../../common/Typography';
import SwitchToggle from '../../common/Form/SwitchToggle';
import OneSignal from 'react-onesignal';

const DEFAULT_MAX_CATEGORIES_DISPLAY = 8;

const EditableProfile = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const LeftPanel = styled.div`
  grid-area: LeftPanel;
  padding-left: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ type }) =>
    type === 'editAccount' &&
    `
    background-color: #F4F5F4;
    padding: 0 2rem;
  `}
`;

const TruncateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Subheader = styled.div`
  margin-bottom: 3rem;
`;

const NotificationSection = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  width: 100px;
  justify-content: space-between;
`;

export function LeftFormSection({
  type,
  errors,
  fileInputRef,
  handleFileUpload,
  setOpenModal,
  userLookingForList,
  topMyAssetCategoriesList,
  user,
  loadingImage,
  onUserFieldChange,
}) {
  const history = useHistory();
  const [
    showAllLookingForCategories,
    setShowAllLookingForCategories,
  ] = useState(false);
  const [
    showAllTopMyAssetCategoriesCategories,
    setShowAllTopMyAssetCategoriesCategories,
  ] = useState(false);
  const userLookingForListDisplay = showAllLookingForCategories
    ? userLookingForList
    : userLookingForList.slice(0, DEFAULT_MAX_CATEGORIES_DISPLAY);
  const topMyAssetCategoriesListDisplay = showAllTopMyAssetCategoriesCategories
    ? topMyAssetCategoriesList
    : topMyAssetCategoriesList.slice(0, DEFAULT_MAX_CATEGORIES_DISPLAY);

  return (
    <LeftPanel type={type}>
      <div>
        <EditableProfile>
          <ProfilePicture
            variant="rounded"
            src={user.profilepicture || Placeholder}
          />
          <EditImageButton
            onClick={handleFileUpload}
            fileInputRef={fileInputRef}
          />
          <Backdrop
            sx={{
              position: 'absolute',
              zIndex: theme => theme.zIndex.drawer + 1,
            }}
            open={loadingImage}
          >
            <CircularProgress color="secondary" />
          </Backdrop>
        </EditableProfile>
        <ErrorFieldMessage
          offsetMargin={false}
          message={errors.profilepicture}
          isVisible={errors.profilepicture}
        />
        <TagDisplay>
          <Editable>
            <SubHeader>Looking for*</SubHeader>
            <EditButton
              onClick={() => setOpenModal('LOOKING_FOR_CATEGORIES')}
            />
          </Editable>
          {type === 'newAccount' && (
            <Typography component="h3">
              Products, services or skills I want to get
            </Typography>
          )}
          {userLookingForListDisplay.map(({ tag }) => (
            <Tag key={`${tag}-top-tag`}>{tag}</Tag>
          ))}
          <TruncateContainer>
            {userLookingForList.length > DEFAULT_MAX_CATEGORIES_DISPLAY && (
              <TruncateButton
                size="small"
                onClick={() =>
                  setShowAllLookingForCategories(!showAllLookingForCategories)
                }
                startIcon={
                  showAllLookingForCategories ? <RemoveIcon /> : <AddIcon />
                }
              >
                {showAllLookingForCategories ? 'Less' : 'More'}
              </TruncateButton>
            )}
          </TruncateContainer>
          <ErrorFieldMessage
            offsetMargin={false}
            message={errors.topcategories}
            isVisible={errors.topcategories}
          />
        </TagDisplay>
        <TagDisplay>
          <Editable>
            <SubHeader>What can you provide in return?*</SubHeader>
            <EditButton onClick={() => setOpenModal('IN_RETURN_CATEGORIES')} />
          </Editable>
          {type === 'newAccount' && (
            <Typography component="h3">
              Skills, products or services I can provide
            </Typography>
          )}
          {topMyAssetCategoriesListDisplay.map(({ tag }) => (
            <Tag key={`${tag}-top-tag`}>{tag}</Tag>
          ))}
          <TruncateContainer>
            {userLookingForList.length > DEFAULT_MAX_CATEGORIES_DISPLAY && (
              <TruncateButton
                size="small"
                onClick={() =>
                  setShowAllTopMyAssetCategoriesCategories(
                    !showAllTopMyAssetCategoriesCategories,
                  )
                }
                startIcon={
                  showAllTopMyAssetCategoriesCategories ? (
                    <RemoveIcon />
                  ) : (
                    <AddIcon />
                  )
                }
              >
                {showAllTopMyAssetCategoriesCategories ? 'Less' : 'More'}
              </TruncateButton>
            )}
          </TruncateContainer>
          <ErrorFieldMessage
            offsetMargin={false}
            message={errors.top_my_asset_categories}
            isVisible={errors.top_my_asset_categories}
          />
        </TagDisplay>
        <TagDisplay>
          <Editable>
            <SubHeader>When I suap, I value*</SubHeader>
            <EditButton onClick={() => setOpenModal('VALUES')} />
          </Editable>
          {user.profilevalues.map(value => {
            return <Tag key={`${value}-tag`}>{value}</Tag>;
          })}
        </TagDisplay>
        <ErrorFieldMessage
          offsetMargin={false}
          message={errors.profilevalues}
          isVisible={errors.profilevalues}
        />
        <div>
          <Subheader>
            <SubHeader variant="body1" gutterBottom>
              Notifications:
            </SubHeader>
            <NotificationSection>
              <Header12>Email:</Header12>
              <SwitchToggle
                defaultChecked={user.notificationmail}
                value={user.notificationmail}
                onChange={onUserFieldChange('notificationmail')}
              />
            </NotificationSection>
            <NotificationSection>
              <Header12>Web:</Header12>
              <SwitchToggle
                defaultChecked={user.notificationpush}
                onChange={e => {
                  if (e.target.checked) {
                    window.localStorage.removeItem(
                      'onesignal-notification-prompt',
                    );
                    OneSignal.Slidedown.promptPush();
                  }

                  onUserFieldChange('notificationpush')(e);
                }}
              />
            </NotificationSection>
          </Subheader>
          <SubHeader>
            <SubHeader variant="body1" gutterBottom>
              Display recent Suaps:
            </SubHeader>
            <SwitchToggle
              defaultChecked={user.show_savings}
              onChange={onUserFieldChange('show_savings')}
            />
          </SubHeader>
        </div>
      </div>
      <LogoutButton history={history} />
    </LeftPanel>
  );
}
