import React from 'react';
import { AssetPost } from './AssetPost';
import { CompletedSuapPost } from './CompletedSuapPost';

export function Post({
  post,
  follow,
  unfollow,
  user,
  isMobile,
  onShowComments,
  onOpenMenuAction,
}) {
  const handleDisplayComments = () => {
    onShowComments(post);
  };

  if (post.type === 'ASSET') {
    return (
      <AssetPost
        key={`${post.type}-${user.id}-${post.data.id}`}
        currentUser={user}
        follow={follow}
        unfollow={unfollow}
        post={post.data}
        onShowComments={handleDisplayComments}
        isFollowing={post.isFollowing}
        onOpenMenuAction={onOpenMenuAction}
        isMobile={isMobile}
      />
    );
  }

  if (post.type === 'CONTRACT') {
    return (
      <CompletedSuapPost
        key={`${post.type}-${user.id}-${post.data.id}`}
        isMobile={isMobile}
        post={post.data}
        onShowComments={handleDisplayComments}
        userContract={
          post.data.requesterUserContract.isFollowing
            ? post.data.requesterUserContract
            : post.data.requestedUserContract
        }
      />
    );
  }
}
