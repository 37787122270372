import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import {validation} from "./validation";
import { capitalizeFirstLetter } from "../../common/utils/capitalizeFirstLetter";
import {postWithEventLog} from "../../analytics/postWithEventLog";
import {eventTrigger} from "../../analytics/eventTrigger";

function postSignupApi(inputs, onSignupSubmission) {
  const { firstname, surname, username, password, isOAuth } = inputs;

  axios
      .post(
          `${process.env.REACT_APP_PUBLIC_API_URL}/users`,
          {
            isOAuth,
            password,
            firstname: capitalizeFirstLetter(firstname),
            surname: capitalizeFirstLetter(surname),
            username: username.toLowerCase(),
            email: username.toLowerCase(),
          },
          {
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          },
      )
      .then(({ data }) => {
        if (data.success) {
          eventTrigger('Sign up completed', 'form', 'new_signup');
        }
        onSignupSubmission(data);
      });
}

function useSignUpForm(callback) {
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    firstname: '',
    surname: '',
    username: '',
    password: '',
    password2: '',
    hasAgreed: '',
  });

  const [errors, setErrors] = useState({
    firstname: '',
    surname: '',
    username: '',
    password: '',
    password2: '',
    hasAgreed: '',
  });

  const onSignupSubmission = (data) => {
    if (data.created === true) {
      setLoading(false);
      callback(true);
    } else {
      setLoading(false);
      toast.error(data.error);
    }
  }

  const handleAuthSubmit = (inputs) => {
      setLoading(true);
      eventTrigger('Sign up with Auth0');
      postSignupApi(inputs, onSignupSubmission);
  };

  const handleSignUpSubmit = (event) => {
    event?.preventDefault();
    eventTrigger('Sign up submission', 'form', 'form_submission');
    const validations = validation();
    const errorFields = Object.entries(inputs).reduce((err, entries) => {
      err[entries[0]] = validations[entries[0]](entries, inputs);
      return err;
    }, {});
    setErrors(errorFields);
    if (event && !Object.values(errorFields).some(v => v)) {
      setLoading(true);
      postSignupApi(inputs, onSignupSubmission);
    }
  };

  const handleInputChange = event => {
    event.persist();
    const newInput = {
      ...inputs,
      [event.target.name]: event.target.value,
    };
    setInputs(newInput);
  };

  const handleCheckboxChange = event => {
    event.persist();
    const newInput = {
      ...inputs,
      [event.target.name]: event.target.checked,
    };
    setInputs(newInput);
  }

  return {
    handleCheckboxChange,
    handleInputChange,
    handleSignUpSubmit,
    handleAuthSubmit,
    inputs,
    loading,
    errors,
  };
}

export default useSignUpForm;
