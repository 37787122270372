const required = message => ([field, value]) => {
  return !value.toString().trim() ? message || `${field} is required.` : '';
};

const requiredNumeral = message => ([field, value]) => {
  return isNaN(value) || !value ? message || `${field} is required.` : '';
};

const maxLength = (maxLength, message) => ([field, value]) => {
  return value && value.length > maxLength ? message || `${field} is .` : '';
};

const requiredList = message => ([field, value]) => {
  return !value || value.length === 0 ? message || `${field} is required.` : '';
};

const allOf = validations => field => {
  let err = '';
  validations.every(validation => {
    err = validation(field);
    return !err;
  });

  return err;
};

export function validation() {
  return {
    firstname: required('First name is required.'),
    surname: required('Last name is required.'),
    profilepicture: required('Profile picture is required.'),
    profilevalues: requiredList('At least one value is required.'),
    ethnicity: requiredList('At least one ethnicity is required.'),
    topcategories: requiredList('At least one top category is required.'),
    top_my_asset_categories: requiredList(
      'At least one top category is required.',
    ),
    displaylocation: required('Location is required.'),
    description: allOf([
      required('Bio is required.'),
      maxLength(800, 'Bio must be 800 characters or less.'),
    ]),
    username: required('Email is required.'),
    phone: required('Mobile number is required.'),
  };
}
