import axios from 'axios';
import _ from 'lodash';
import { useContext, useState } from 'react';
import { AuthContext } from '../../app/AuthContext';
import { genericSuccess, genericUpdateError } from '../../toaster/utils';
import { predefineCompleteSuap } from '../predefinedMessages';
import { eventTrigger } from '../../analytics/eventTrigger';

function useUpdateChatStatus(
  onUpdateSuapContract,
  currentUser,
  setOpenContractModal,
  fetchChatDetailsBySerialId,
) {
  const { authContext } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const updateContractStatus = (chatId, serialId, status) => {
    setIsLoading(true);
    axios
      .put(
        `${process.env.REACT_APP_PUBLIC_API_URL}/chat/${chatId}`,
        { serialId, status },
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'x-access-token': authContext.token,
          },
        },
      )
      .then(response => {
        eventTrigger('Completed suap', 'form', 'complete_suap');
        setIsLoading(false);
        onUpdateSuapContract(predefineCompleteSuap(currentUser.firstName));
        setOpenContractModal('REVIEW_FORM');
        genericSuccess('You successfully completed the suap.');
        return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        genericUpdateError(`Failed to complete the suap: ${error}`);
      });
  };

  return { updateContractStatus, isLoading };
}

export default useUpdateChatStatus;
