import { useContext, useState } from 'react';
import { AuthContext } from '../../app/AuthContext';
import axios from 'axios';
import * as Contract from '../../common/models/contract';
import * as ContractMetrics from '../../common/models/contractMetrics';
import _ from 'lodash';

export default function useSuapContract(userId, status) {
  const { authContext } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [contractDetails, setContractDetails] = useState([]);
  const [suapMetrics, setSuapMetrics] = useState({});

  const fetchContract = () => {
    axios
      .get(
        `${
          process.env.REACT_APP_PUBLIC_API_URL
        }/contracts/user/${userId}?status=${status ?? 'COMPLETED'}`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'x-access-token': authContext.token,
          },
        },
      )
      .then(response => {
        setContractDetails((response.data ?? []).map(Contract.fromApi));
        fetchSuapMetrics();
        return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
        setLoaded(true);
        setLoading(false);
      });
  };

  const fetchSuapMetrics = () => {
    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_API_URL}/contracts/user/${userId}/savings`,
        {
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'x-access-token': authContext.token,
          },
        },
      )
      .then(response => {
        setSuapMetrics(ContractMetrics.fromApi(response.data ?? {}));
        setLoaded(true);
        setLoading(false);
        return response.data;
      })
      .catch(error => {
        if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
      });
  };

  if (userId && _.isEmpty(contractDetails) && !loading && !loaded) {
    setLoading(true);
    fetchContract();
  }

  return {
    contractDetails,
    suapMetrics,
    loading,
  };
}
