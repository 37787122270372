import { useCallback, useContext, useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";
import axios from "axios";
import { AuthContext } from "../../app/AuthContext";
import { genericUpdateError } from "../../toaster/utils";
import { isEmpty } from "lodash";

function getDisplayLocation(data) {
    if (data?.city && data?.administrativeLevels?.level1short) {
        const { city, country } = data;
        const { level1short } = data.administrativeLevels;
        return `${city}, ${level1short}, ${country}`;
    }

    return '';
}

function getLocationCoordinates(data) {
    if (!data.latitude || !data.longitude) {
        return [];
    }

   return [data.latitude, data.longitude];
}

export default function useEnableLocation(callback) {
    const { authContext } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [isTriggered, setIsTriggered] = useState(false);
    const [location, setLocation] = useState({});

    const {
        coords,
        isGeolocationAvailable,
        isGeolocationEnabled,
        getPosition,
    } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });

    const [coordinates, setCoordinates] = useState(coords);

    useEffect(() => {
        if (coords && !coordinates) {
            setCoordinates(coords);
        }
    }, [coords]);

    const handleEnableLocation = useCallback(() => {
        setLoading(true);
        setIsTriggered(true);
        if (coordinates) {
            axios
                .get(`${process.env.REACT_APP_PUBLIC_API_URL}/location/coords?lat=${coordinates.latitude}&lon=${coordinates.longitude}`,
                    {
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                            'x-access-token': authContext.token,
                        },
                    })
            .then(response => {
                setLoaded(true);
                setLoading(false);
                const location = {
                    displaylocation: getDisplayLocation(response.data?.[0]),
                    location: getLocationCoordinates(response.data?.[0]),
                    country: response.data?.[0].country,
                }
                setLocation(location);
                callback(location);
            })
            .catch(error => {
                setLoaded(true);
                setLoading(false);
                if (process.env.REACT_APP_VERBOSE === 'true') console.log(error);
            });
        } else if (!isGeolocationAvailable || !isGeolocationEnabled) {
            setLoaded(true);
            setLoading(false);
            genericUpdateError('Unable to determine your location. Please check your network settings.');
        }
    }, [coordinates, setLoading, callback]);

    if (coordinates && isTriggered && !loading && !loaded && isEmpty(location)) {
        setLoading(true);
        handleEnableLocation();
    }

    return {
        loading,
        handleEnableLocation,
        locationDisabled: !isGeolocationEnabled,
    }
}