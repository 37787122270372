import { useHistory } from 'react-router-dom';
import { useCallback, useState } from 'react';
import useFetchAssetDetails from '../hooks/useFetchAssetDetails';
import useGetCategories from '../../common/hooks/useGetCategories';
import useEditAsset from '../hooks/useEditAsset';
import useCreateChat from '../hooks/useCreateChat';
import useFetchReviews from '../../review/useFetchReviews';
import FORMAT_SIZES from '../../common/constants/FormatSizes';
import { Mobile } from './mobile';
import { Web } from './web';

export function ViewUserAssetDetailPage({ format, match, userInfo = {} }) {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(null);

  const { asset, fetchAsset } = useFetchAssetDetails(match.params.id);
  const { categories, loading: loadingCategories } = useGetCategories();

  const openAssetForm = () => {
    setOpenModal('ASSET_FORM');
  };

  const openShareAsset = () => {
    setOpenModal('ASSET_SHARE');
  };

  const onClose = () => {
    setOpenModal(null);
  };

  const refetchAsset = () => {
    fetchAsset(asset.uuid);
  };

  const { updateAsset, loading: updating, resetErrors, errors } = useEditAsset(
    onClose,
    refetchAsset,
  );
  const { initiateSuap, loading } = useCreateChat(history);
  const { reviews: userReviews } = useFetchReviews('user', asset?.user?.uuid);
  const { reviews: assetReviews } = useFetchReviews('asset', asset.id);
  const suapDisabled = userInfo.id && Number(userInfo.activeassetcount) === 0;

  const handleSuap = useCallback(() => {
    if (!userInfo.id) {
      history.push('/login');
    } else if (asset.serialId) {
      history.push(`/chat/${asset.serialId}`);
    } else {
      const predefinedMessage = '[PREDEFINED]Say hi and edit your offer above';
      initiateSuap({
        predefinedMessage,
        ownerId: asset.user.id,
        assetId: asset.id,
      });
    }
  }, [userInfo.id, asset]);

  const redirectToAsset = () => {
    window.location = '/suap-wallet?message=add_assets_to_suap';
  };

  const suapMethod = suapDisabled ? redirectToAsset : handleSuap;

  if (format === FORMAT_SIZES.MOBILE) {
    return (
      <Mobile
        openModal={openModal}
        categories={categories}
        loadingCategories={loadingCategories}
        openAssetForm={openAssetForm}
        onClose={onClose}
        updateAsset={updateAsset}
        onSuap={suapMethod}
        asset={asset}
        errors={errors}
        updating={updating}
        resetErrors={resetErrors}
        assetReviews={assetReviews}
        userReviews={userReviews}
        userInfo={userInfo}
        openShareAsset={openShareAsset}
      />
    );
  }

  return (
    <Web
      openModal={openModal}
      categories={categories}
      loadingCategories={loadingCategories}
      openAssetForm={openAssetForm}
      onClose={onClose}
      updateAsset={updateAsset}
      onSuap={suapMethod}
      asset={asset}
      errors={errors}
      updating={updating}
      resetErrors={resetErrors}
      assetReviews={assetReviews}
      userReviews={userReviews}
      userInfo={userInfo}
    />
  );
}
