import React, { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Tooltip, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import useFetchUserAssets from './useFetchUserAssets';
import styled from 'styled-components';
import Placeholder from '../../../assets/images/placeholderImage.png';
import moment from 'moment';
import DialogForm from '../../common/DialogForm';
import useCreateOrUpdateContract from './useCreateOrUpdateContract';
import {
  predefineFirstPersonEditing,
  predefineSecondPersonEditing,
} from '../predefinedMessages';
import { THEME_COLORS } from '../../common/colors';
import { SelectField } from '../../common/Form/SelectField';
import { TextFormField } from '../../common/Form/TextFormField';
import { DialogContent } from '@material-ui/core';
import { DateFormField } from '../../common/Form/DatePicker';
import { styled as styledMUI } from '@mui/material/styles';
import FORMAT_SIZES from '../../common/constants/FormatSizes';
import { ReactSVG } from 'react-svg';
import Arrow from '../../../assets/arrow.svg';

const AssetContainer = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'row' : 'row')};
  justify-content: center;
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'center')};
  margin-bottom: 3rem;
`;

const FormField = styled(Grid)`
  margin-bottom: 1.5rem !important;
`;

const AssetPanel = styledMUI(Box)(({ isMobile }) => ({
  flexDirection: `${isMobile ? 'row' : 'column'} !important`,
  gap: `${isMobile ? 12 : 0}px !important`,
  textAlign: `${isMobile ? 'left' : 'center'} !important`,
  ...(isMobile ? { width: '100%', maxWidth: '240px' } : {}),
  justifyContent: `${isMobile ? 'center' : 'center'} !important`,
  alignItems: 'center !important',
}));

const SuapIcon = styled(ReactSVG)`
  svg {
    height: ${({ isMobile }) => (isMobile ? 30 : 50)}px !important;
  }
  display: block !important;
  fill: ${THEME_COLORS.primary}!important;
  ${({ flipped }) =>
    flipped &&
    `
    transform: scale(-1, -1) !important;
  `}
`;

const AssetMainImage = styled(Avatar)`
  width: ${({ isMobile }) => (isMobile ? 85 : 100)}% !important;
  max-width: 150px !important;
  height: auto !important;
  margin-bottom: 0.7rem !important;
`;

const FormTextField = styled(TextFormField)`
  .MuiInputBase-root {
    margin-bottom: 0.1rem !important;
  }
  .Mui-error {
    margin-left: 0 !important;
  }
  .MuiInputBase-input {
    padding: 4px !important;
  }
`;

const FormDateField = styled(DateFormField)`
  .MuiInputBase-root {
    margin-bottom: 0.1rem !important;
  }
  .Mui-error {
    margin-left: 0 !important;
  }
  .MuiInputBase-input {
    padding: 4px !important;
  }
`;

const TextAreaLimit = styled.div`
  &,
  span {
    color: ${THEME_COLORS.primary} !important;
  }
  text-align: right;
  font-size: 0.8rem;
`;

function initializeForm(contractUser, contractId, userData) {
  return {
    id: contractUser.id,
    contractId,
    userId: userData.id,
    description: contractUser.description ?? '',
    estimatedCost: contractUser.estimatedCost,
    dateOfCompletion: contractUser.dateOfCompletion
      ? moment(contractUser.dateOfCompletion)
      : null,
    asset: contractUser.asset,
  };
}

export default function ContractForm({
  contractId,
  contractUser = {},
  otherUser = {},
  otherContractUser = {},
  userData = {},
  setOpenContractModal,
  initContract,
  onUpdateSuapContract,
  isMobile,
  asset,
  isOpen,
  format,
}) {
  const [selectedAsset, setSelectedAsset] = useState(contractUser.asset ?? {});
  const [contractFormData, setContractFormData] = useState(
    initializeForm(contractUser, contractId, userData),
  );
  useEffect(() => {
    if (contractUser.asset) {
      setContractFormData(initializeForm(contractUser, contractId, userData));
      setSelectedAsset(contractUser.asset);
    }
  }, [contractUser, contractId, userData]);

  const { updateContract, createContract, errors } = useCreateOrUpdateContract(
    setOpenContractModal,
    onUpdateSuapContract,
  );
  const { userAssets } = useFetchUserAssets(
    userData,
    contractFormData,
    setSelectedAsset,
    setContractFormData,
  );
  const handleValueChange = field => value => {
    setContractFormData({
      ...contractFormData,
      [field]: value,
    });
  };
  const handleEventChange = field => e => {
    handleValueChange(field)(e.target.value);
  };

  const handleSave = () => {
    const message = otherContractUser.asset?.title
      ? predefineSecondPersonEditing(otherUser.firstName, userData.firstName)
      : predefineFirstPersonEditing(otherUser.firstName, userData.firstName);
    const { contractId, ...form } = contractFormData;
    if (contractId && contractUser.id) {
      updateContract(
        {
          ...contractFormData,
          id: contractUser.id,
        },
        message,
      );
    } else {
      createContract(
        {
          ...initContract,
          ...form,
        },
        message,
      );
    }
  };

  const handleCancel = () => {
    setContractFormData(initializeForm(contractUser, contractId, userData));
    setOpenContractModal(null);
  };

  return (
    <DialogForm
      label="Submit My Offer"
      description=""
      isOpen={isOpen}
      onSave={handleSave}
      width={640}
      buttonLabel="Submit"
      isMobile={isMobile}
      hideCloseButton={true}
      onClose={() => setOpenContractModal(null)}
      onCancel={handleCancel}
    >
      <DialogContent>
        <AssetContainer isMobile={format === FORMAT_SIZES.MOBILE}>
          <AssetPanel
            sx={{
              display: {
                xs: 'flex',
                sm: 'inline-flex',
                md: 'inline-flex',
                lg: 'inline-flex',
              },
            }}
            isMobile={format === FORMAT_SIZES.MOBILE}
          >
            <AssetMainImage
              variant="round"
              src={
                otherContractUser.asset?.mainImage ||
                asset?.mainImage ||
                Placeholder
              }
              isMobile={format === FORMAT_SIZES.MOBILE}
            />
          </AssetPanel>
          <AssetPanel
            sx={{
              display: {
                xs: 'flex',
                sm: 'inline-flex',
                md: 'inline-flex',
                lg: 'inline-flex',
              },
            }}
          >
            <SuapIcon isMobile={isMobile} src={Arrow} />
            <SuapIcon flipped={true} isMobile={isMobile} src={Arrow} />
          </AssetPanel>
          <AssetPanel
            sx={{
              display: {
                xs: 'flex',
                sm: 'inline-flex',
                md: 'inline-flex',
                lg: 'inline-flex',
              },
            }}
            isMobile={format === FORMAT_SIZES.MOBILE}
          >
            <AssetMainImage
              variant="round"
              src={selectedAsset.mainImage || Placeholder}
              isMobile={format === FORMAT_SIZES.MOBILE}
            />
          </AssetPanel>
        </AssetContainer>

        <Grid container>
          <Grid item xs={12} sm={2} lg={2}>
            <Typography variant="body1" gutterBottom>
              Suaping for*
            </Typography>
          </Grid>
          <FormField item xs={12} sm={10} lg={10}>
            <FormTextField
              variant="outlined"
              value={
                otherContractUser.asset?.title ||
                asset?.title ||
                ' Waiting for asset'
              }
              disabled
              fullWidth
            />
          </FormField>
          <Grid item xs={12} sm={2} lg={2}>
            <Typography variant="body1" gutterBottom>
              Your offer*
            </Typography>
          </Grid>
          <FormField item xs={12} sm={10} lg={10}>
            <SelectField
              id="filled-select-asset"
              select
              error={!!errors.asset}
              helperText={errors.asset}
              value={selectedAsset.id ?? 0}
              variant="filled"
              color="warning"
              style={{ width: '100%' }}
              onChange={e => {
                const value = e.target.value;
                const asset =
                  userAssets.find(asset => asset.id === value) ?? {};
                setSelectedAsset(asset);
                handleValueChange('asset')(asset);
              }}
            >
              {userAssets.map(option => (
                <MenuItem
                  key={`asset-${option.title}-${option.id}`}
                  value={option.id}
                >
                  {option.title}
                </MenuItem>
              ))}
            </SelectField>
          </FormField>
          <Grid container>
            <Grid item xs={12} sm={2} lg={2}>
              <Typography variant="body1" gutterBottom>
                Details*
              </Typography>
            </Grid>
            <FormField item xs={12} sm={10} lg={10}>
              <FormTextField
                error={!!errors.description}
                helperText={errors.description}
                id="field-description"
                multiline
                placeholder="Add relevant details for expectation management and accountability."
                rows={2}
                variant="filled"
                value={contractFormData.description}
                onChange={handleEventChange('description')}
              />
            </FormField>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={2} lg={2}>
              <Typography variant="body1" gutterBottom>
                Suap by*
              </Typography>
            </Grid>
            <FormField item xs={12} sm={10} lg={10}>
              <FormDateField
                error={!!errors.dateOfCompletion}
                helperText={errors.dateOfCompletion}
                value={contractFormData.dateOfCompletion}
                onChange={handleValueChange('dateOfCompletion')}
                slotProps={{
                  textField: { variant: 'filled', color: 'warning' },
                }}
              />
            </FormField>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={2} lg={2}>
              <Typography variant="body1" gutterBottom>
                Trade value*
              </Typography>
            </Grid>
            <FormField item xs={12} sm={10} lg={10}>
              <FormTextField
                id="standard-number"
                placeholder=" Enter a number value"
                type="number"
                error={!!errors.estimatedCost}
                helperText={errors.estimatedCost}
                inputMode="numeric"
                value={contractFormData.estimatedCost}
                onChange={e => {
                  const value = e.target.value < 0 ? 0 : e.target.value;
                  handleValueChange('estimatedCost')(value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
              />
              <TextAreaLimit>
                <Tooltip
                  arrow
                  placement="bottom"
                  title="While we're moving beyond money, providing a trade estimate helps keep exchanges balanced and lets us highlight the value you're gaining in our cash-free community."
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                >
                  <span>Why this?</span>
                </Tooltip>
              </TextAreaLimit>
            </FormField>
          </Grid>
        </Grid>
      </DialogContent>
    </DialogForm>
  );
}
