import React from "react";
import { AssetList, Thumbnail } from "../styledComponents";
import { Typography } from "@mui/material";
import EveryoneHasSomethingToAd from "../../../assets/ads/everyone_has_something_to_ad.gif";
import styled from "styled-components";
import IconButton from '@mui/material/IconButton';
import { AddNewAsset } from "../../assets/components/AddNewAsset";

const RightPanel = styled.div`
  grid-area: RightPanel;
  background-color: #F4F5F4;
`;

const AdContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export function RightFormSection({ type, userAssets, setAssetId, setOpenModal }) {
    const openAssetForm = (id) => {
        setAssetId(id);
        setOpenModal('ASSET_FORM');
    }

    return (
        <RightPanel>
            {
                userAssets.length === 0 &&
                <AdContainer>
                    <img
                        style={{ width: '100%', padding: '0 12px' }}
                        src={EveryoneHasSomethingToAd}
                        alt="Everyone Has Something To Ad"
                    />
                </AdContainer>
            }
            {
                userAssets.length > 0 &&
                <AssetList>
                    {
                        userAssets.map((asset, i) => {
                                return (
                                    <div key={`asset-list-${asset.category}-${i}`} style={{margin: 4, maxWidth: 80}}>
                                        <Thumbnail onClick={ () => openAssetForm(asset.uuid)}>
                                            <img alt="placeholder" src={asset.mainImage} />
                                        </Thumbnail>
                                        <Typography variant="body2">{asset.title}</Typography>
                                    </div>
                                )
                            })
                    }
                    {
                        userAssets.length < 6 &&
                        <div key="new-asset-create-card" style={{ margin: 4, maxWidth: 80}}>
                            <AddNewAsset
                                size="thumbnail"
                                header={<Typography sx={{ marginTop: '5px !important' }} variant="body2">Add new</Typography>}
                                openAssetForm={() => openAssetForm(null)} />
                        </div>
                    }
                </AssetList>
            }
        </RightPanel>
    );
}