import React from 'react';
import styled from 'styled-components';
import Link from '@mui/material/Link';
import { useHistory, useLocation } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonIcon from '@mui/icons-material/PersonRounded';
import SearchIcon from '@mui/icons-material/Search';
import ChatIcon from '@mui/icons-material/Chat';
import { Grid, Tooltip } from '@mui/material';
import DiscoveryIcon from '@mui/icons-material/ExploreRounded';

const MinimalLink = styled(Link)`
  color: #4e5152 !important;
  text-decoration: unset !important;
  align-self: center !important;
  font-weight: bold !important;
  padding-bottom: 4px !important;
  box-sizing: border-box !important;
  height: 20px !important;
  font-weight: 800 !important;

  ${({ $isActive }) =>
    $isActive &&
    `
    border-bottom: 1px #4e5152 solid !important;
  `}
  &:hover {
    border-bottom: 1px #4e5152 solid !important;
  }
`;

const NavContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
`;

const BottomNavContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 65px;
  background-color: #fff;
  z-index: 1099;
`;

const BottomAction = styled(BottomNavigationAction)`
  min-width: 0px !important;
  span {
    display: none !important;
  }
  svg {
    font-size: 24px !important;
  }
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  ${({ condensed }) => condensed && 'max-width: 550px;'}
  align-items: center;
  justify-content: space-between;
`;

function FullSizeNavBar({ condensed, location }) {
  return (
    <NavContainer condensed={condensed}>
      <LinkContainer condensed={condensed}>
        {false && (
          <MinimalLink href="https://join.wesuap.com/">About us</MinimalLink>
        )}
        <MinimalLink
          href="/search"
          $isActive={location.pathname.includes('search')}
        >
          Search
        </MinimalLink>
        {false && (
          <MinimalLink
            href="/discovery"
            $isActive={location.pathname === '/discovery'}
          >
            Discovery
          </MinimalLink>
        )}
        <MinimalLink
          href="/suap-wallet"
          $isActive={location.pathname.includes('assets')}
        >
          Suap Wallet
        </MinimalLink>
        <MinimalLink
          href="/profile"
          $isActive={location.pathname.includes('profile')}
        >
          Profile
        </MinimalLink>
        {false && (
          <MinimalLink
            href="/history"
            $isActive={location.pathname === '/history'}
          >
            History
          </MinimalLink>
        )}
        {false && (
          <Tooltip title="Feature coming soon!" arrow placement="bottom">
            <MinimalLink href="#" $isActive={location.pathname === '/likes'}>
              Favorites
            </MinimalLink>
          </Tooltip>
        )}
        <MinimalLink
          href="/chat"
          $isActive={location.pathname.includes('chat')}
        >
          Chat
        </MinimalLink>
      </LinkContainer>
    </NavContainer>
  );
}

function MobileNavBar({ history }) {
  const handleChange = (e, newValue) => {
    history.push(`/${newValue}`);
  };

  return (
    <BottomNavContainer>
      <BottomNavigation onChange={handleChange}>
        <BottomAction value="search" label="Search" icon={<SearchIcon />} />
        {false && (
          <BottomAction
            value="discovery"
            label="Discovery"
            icon={<DiscoveryIcon />}
          />
        )}
        <BottomAction
          value="suap-wallet"
          label="Suap wallet"
          icon={<InventoryIcon />}
        />
        <BottomAction value="profile" label="Profile" icon={<PersonIcon />} />
        {false && (
          <BottomAction
            sx={{ display: { xs: 'none' } }}
            value="history"
            label="History"
            icon={<RestoreIcon />}
          />
        )}
        {false && (
          <BottomAction
            sx={{ display: { xs: 'none' } }}
            value="likes"
            label="Favorites"
            icon={<FavoriteIcon />}
          />
        )}
        <BottomAction value="chat" label="Chat" icon={<ChatIcon />} />
      </BottomNavigation>
    </BottomNavContainer>
  );
}
export function NavBar({ condensed }) {
  const location = useLocation();
  const history = useHistory();
  return (
    <Grid container>
      <Grid
        item
        lg={12}
        md={12}
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
        }}
      >
        <FullSizeNavBar condensed={condensed} location={location} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
            xl: 'none',
          },
        }}
      >
        <MobileNavBar history={history} />
      </Grid>
    </Grid>
  );
}
