import React, { useRef } from 'react';
import styled from 'styled-components';
import DialogForm from '../../common/DialogForm';
import { CompletedSuapPost } from '../components/CompletedSuapPost';
import { ImageCarousel } from '../../common/ImageCarousel';
import Placeholder from '../../../assets/images/placeholderImage.png';
import { CommentForm } from './CommentForm';
import { useGetCommentsForPost } from './useGetCommentsForPost';
import { PostActionBar } from '../components/PostActionBar';
import { Avatar, Grid } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { FollowUserHeader } from '../../follow/components/FollowUserHeader';
import Link from '@material-ui/core/Link';
import { Body10, Header10, Header12 } from '../../common/Typography';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteIcon from '@mui/icons-material/FavoriteRounded';
import { THEME_COLORS } from '../../common/colors';
import IconButton from '@mui/material/IconButton';
import { useCommentLike } from './useCommentLike';
import { he } from 'date-fns/locale';

const AssetImage = styled.div`
  max-width: 100%;

  display: flex;
  justify-content: center;
  overflow: hidden;

  img {
    flex-shrink: 0;
    width: 115%;
    min-height: 100%;
  }
`;

const Header = styled.div`
  margin-top: -18px;
  padding-bottom: 1rem;
  border-bottom: 1px #f4f5f4 solid;
`;

const CommentList = styled.div`
  ${({ isMobile, heightOffset }) =>
    !isMobile && `height: calc(100% - 170px - ${heightOffset}px);`}
  ${({ isMobile, type }) =>
    (isMobile || type !== 'ASSET') && 'padding-top: 1.5rem;'}
  overflow-y: auto;
`;

const UserDetails = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CommentContent = styled.div`
  display: flex;
`;

const CommentSubheader = styled.div`
  display: flex;
  margin-top: 0.4rem;
  gap: 0.8rem;
  color: #737373 !important;
`;

const CommentContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0.7rem;
`;

const MinimalLink = styled(Link)`
  color: #4e5152 !important;
  text-decoration: unset !important;
`;

const UserProfile = styled(Avatar)`
  width: 30px !important;
  height: 30px !important;
  margin-right: 1.3rem !important;
`;

const DescriptionContainer = styled.div``;
const FilledLikeIcon = styled(FavoriteIcon)`
  fill: ${THEME_COLORS.primary} !important;
`;

const MenuContainer = styled(IconButton)`
  padding: 0;
  svg {
    font-size: ${({ isMobile }) => (isMobile ? '1.5rem' : '0.9rem')} !important;
  }
`;

const PostViewContainer = muiStyled(Grid)(() => ({
  '&': {
    padding: '2.5rem',
  },
}));

const CommentListContainer = muiStyled(Grid)(({ isMobile, type }) => ({
  '&':
    isMobile || type === 'CONTRACT'
      ? {}
      : {
          paddingLeft: '1.5rem',
        },
}));

function PostPreview({ post, type }) {
  if (type === 'ASSET') {
    return (
      <div>
        <ImageCarousel
          animation="slide"
          autoPlay={false}
          indicators={post.images.length > 1}
        >
          {post.images.map((img, i) => {
            return (
              <AssetImage>
                <img
                  src={img || Placeholder}
                  alt={`${post.title} image ${i + 1}`}
                />
              </AssetImage>
            );
          })}
        </ImageCarousel>
      </div>
    );
  }

  if (type === 'CONTRACT') {
    return (
      <CompletedSuapPost
        isPreview={true}
        post={post}
        userContract={
          post.requesterUserContract.isFollowing
            ? post.requesterUserContract
            : post.requestedUserContract
        }
      />
    );
  }
}

function CommentDisplay({
  description,
  user,
  createdAt,
  commentId,
  likesCount,
  likeId,
  isMobile,
}) {
  const { isLiked: isCommentLiked, likeNum, handleChangeLike } = useCommentLike(
    commentId,
    likeId,
    likesCount,
  );
  const LikeComponent = isCommentLiked ? FilledLikeIcon : FavoriteBorderIcon;

  return (
    <CommentContentContainer>
      <CommentContent>
        <UserDetails>
          <MinimalLink href={`/profile/${user.id}`}>
            <UserProfile alt="user-profile-picture" src={user.profilePicture} />
          </MinimalLink>
        </UserDetails>
        <div>
          {user.firstName && description && (
            <DescriptionContainer>
              <Header12 as="span" style={{ marginRight: '0.5rem' }}>
                {user.firstName}
              </Header12>
              {description}
            </DescriptionContainer>
          )}
          <CommentSubheader>
            <Body10 as="p">{createdAt}</Body10>
            {!!likeNum && (
              <Header10 as="p">
                {likeNum} like{likeNum === 1 ? '' : 's'}
              </Header10>
            )}
          </CommentSubheader>
        </div>
      </CommentContent>
      {commentId && (
        <MenuContainer
          onClick={handleChangeLike}
          type="button"
          aria-label="toggle-like-action"
          isMobile={isMobile}
        >
          <LikeComponent />
        </MenuContainer>
      )}
    </CommentContentContainer>
  );
}
export function PostCommentModal({
  isMobile,
  isOpen,
  onClose,
  type,
  follow,
  unfollow,
  isFollowing,
  post = {},
  currentUser,
  onOpenMenuAction,
}) {
  const { loading, commentList, getComments } = useGetCommentsForPost(
    post.id,
    type,
  );
  const headerRef = useRef(null);

  return (
    <DialogForm
      label=""
      description=""
      closeLabel=""
      buttonLabel=""
      onClose={onClose}
      maxWidth={type !== 'CONTRACT' ? '70vw' : undefined}
      width={type !== 'CONTRACT' ? '70vw' : undefined}
      isMobile={isMobile}
      isOpen={isOpen}
    >
      {!!type && (
        <PostViewContainer container sx={{ width: '100%' }}>
          {(!isMobile || type === 'CONTRACT') && (
            <Grid
              item
              xs={12}
              sm={12}
              md={type === 'CONTRACT' ? 12 : 6}
              lg={type === 'CONTRACT' ? 12 : 6}
            >
              <PostPreview post={post} type={type} />
            </Grid>
          )}
          <CommentListContainer
            isMobile={isMobile}
            item
            xs={12}
            sm={12}
            md={type === 'CONTRACT' ? 12 : 6}
            lg={type === 'CONTRACT' ? 12 : 6}
            type={type}
          >
            {type === 'ASSET' && (
              <Header ref={headerRef}>
                <FollowUserHeader
                  post={{
                    id: post.assetId,
                    assetUuid: post.assetUuid,
                    user: post.user,
                    serialId: post.serialId,
                  }}
                  currentUser={currentUser}
                  user={post.user}
                  follow={follow}
                  unfollow={unfollow}
                  isFollowing={isFollowing}
                  title={post.title}
                  isMobile={isMobile}
                  onOpenMenuAction={onOpenMenuAction}
                />
              </Header>
            )}
            <CommentList
              isMobile={isMobile}
              heightOffset={(headerRef.current?.offsetHeight ?? 0) - 100}
            >
              {type !== 'CONTRACT' && (
                <CommentDisplay
                  description={post.description}
                  user={post.user}
                />
              )}
              {commentList.map(comment => (
                <CommentDisplay
                  key={`comment-${comment.id}`}
                  description={comment.description}
                  user={comment.user}
                  createdAt={comment.createdAt}
                  commentId={comment.id}
                  likesCount={comment.likesCount}
                  likeId={comment.likeId}
                  isMobile={isMobile}
                />
              ))}
            </CommentList>
            <PostActionBar
              postId={post.id}
              type={type}
              currentLikeNum={post.likesCount}
              userFirstname={post.user?.firstName}
              likeId={post.likeId}
              assetUuid={post.assetUuid}
              hideShare={isMobile}
              assetTitle={post.title}
              postMainImage={post.images?.[0]}
            />
            <CommentForm
              postId={post.id}
              postType={type}
              isMobile={isMobile}
              getComments={getComments}
            />
          </CommentListContainer>
        </PostViewContainer>
      )}
    </DialogForm>
  );
}
