import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { styled as styledMUI } from '@mui/material/styles';
import { Ratings } from '../common/RatingDisplays';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

const LeftSection = styled(Grid)`
  background-color: #f4f5f4 !important;
  padding: 12px 8px !important;
  ${({ isMobile }) => !isMobile && 'margin-bottom: 24px !important;'}
`;

const RightSection = styled(Grid)`
  background-color: #f4f5f4 !important;
  padding: 12px !important;
  ${({ isMobile }) => !isMobile && 'margin-bottom: 24px !important;'}
`;

const ReviewContainer = styled(Grid)`
  display: flex !important;
  justify-content: space-between !important;
`;

const Title = styledMUI(Typography)(() => ({
  color: '#4E5152 !important',
  fontWeight: 'bold !important',
  fontSize: '1.05rem !important',
  marginBottom: '8px !important',
}));

const SuapInfo = styledMUI(Typography)(() => ({
  color: '#4E5152 !important',
  fontSize: '0.70rem !important',
}));

const RatingValue = styledMUI(Typography)(() => ({
  color: '#4E5152 !important',
  fontSize: '0.85rem !important',
  marginLeft: '12px !important',
}));

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RatingDescription = styled.div`
  margin-top: 8px;
  font-size: 0.9rem !important;
`;

function Review({ review, userContract, reviewerContract, type, isMobile }) {
  return (
    <ReviewContainer container>
      <LeftSection item xs={12} sm={4} lg={3} isMobile={isMobile}>
        <Title>
          {review.reviewer.firstName} {review.reviewer.surname}
        </Title>
        <SuapInfo>Suaped a:</SuapInfo>
        <SuapInfo>
          {reviewerContract.assetQuantity} {reviewerContract.assetTitle}
        </SuapInfo>
        <SuapInfo>
          For: {userContract.assetQuantity} {userContract.assetTitle}
        </SuapInfo>
      </LeftSection>
      <RightSection item xs={12} sm={7.5} lg={8.5} isMobile={isMobile}>
        <RatingContainer isMobile={isMobile}>
          <Ratings
            name="read-only"
            readOnly
            size="medium"
            value={review.userRating ?? 0}
            precision={0.5}
          />
          <RatingValue>{review[`${type}Rating`]} out of 5</RatingValue>
        </RatingContainer>
        <RatingDescription>{review.reviewText}</RatingDescription>
      </RightSection>
    </ReviewContainer>
  );
}

function Reviews({ reviews, type, isMobile }) {
  return reviews.reviews.map(review => {
    const reviewerContract = reviews.reviewerContracts.filter(
      c => c.contractId === review.contractId,
    )[0];
    const userContract = reviews.userContracts.filter(
      c => c.contractId === review.contractId,
    )[0];

    return (
      <Review
        key={`review-${review.id}`}
        review={review}
        reviewerContract={reviewerContract}
        userContract={userContract}
        type={type}
        isMobile={isMobile}
      />
    );
  });
}
export function ReviewList({ reviews, type, isMobile }) {
  if (isMobile) {
    return (
      <Stack divider={<Divider flexItem />} spacing={2}>
        <Reviews reviews={reviews} type={type} isMobile={true} />
      </Stack>
    );
  }

  return <Reviews reviews={reviews} type={type} />;
}
