import React from 'react';
import styled from 'styled-components';
import { THEME_COLORS } from '../../common/colors';

const Title = styled.h1`
  margin-bottom: 8px;
  text-align: center;
`;

const Summary = styled.p`
  margin-bottom: 8px;
  text-align: center;
  line-height: 24px;
`;

const PageContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 36px;
  background-color: #fff;
`;

const AgreementList = styled.ol`
  margin: 20px;
  padding-left: 8px;
  li {
    margin-bottom: 8px;
    line-height: 24px;
  }
`;

export function TermsAndConditions() {
  return (
    <div style={{ backgroundColor: THEME_COLORS.backgroundDecorator }}>
      <PageContainer>
        <Title>NON-DISCLOSURE AGREEMENT</Title>
        <Summary>
          This is an agreement, effective November 06, 2023, between The Suap
          LLC (the "Company") and 2023HolidayTestGroup (the "Tester"), in which
          Tester agrees to test a software program known as WeSuap.com (the
          "Software") and keep the Company aware of the test results.
        </Summary>
        <AgreementList>
          <li>
            <b>Company's Obligations</b>: The Company shall provide Tester with
            a copy of the Software and any necessary documentation and instruct
            Tester on how to use it and the desired test data to be gained and
            hereby grants Tester a nonexclusive, limited license to install the
            Software on its computer system for the sole purpose of testing the
            performance of the Software and advising Company of the results of
            such tests. Upon satisfactory completion of the testing, the Company
            shall furnish Tester with one free copy of the production version of
            the Software, contingent upon the Company's decision to proceed with
            production of the Software. Tester shall be entitled to the same
            benefits to which regular purchasers of the Software will be
            entitled.
          </li>
          <li>
            <b>Tester's Obligations</b>: Tester shall test the Software under
            normally expected operating conditions in Tester's environment
            during the test period. Tester shall gather and report test data as
            mutually agreed upon with the Company. Tester shall allow the
            Company access to the Software during normal working hours for
            inspection, modifications and maintenance.
          </li>
          <li>
            <b>Software a Trade Secret</b>: Tester acknowledges that the
            Software is proprietary to, and a valuable trade secret of, the
            Company and is entrusted to Tester only for the purpose set forth in
            this Agreement. Tester shall treat the Software in the strictest
            confidence. Tester agrees that it will not, without the Company's
            prior written consent:
          </li>
          <AgreementList>
            <li>
              disclose any information about the Software, its design and
              performance specifications, its code, and the existence of the
              beta test and its results to anyone other than Tester's employees
              who are performing the testing;
            </li>
            <li>
              copy any portion of the Software or documentation, except to the
              extent necessary to perform the beta testing; or
            </li>
            <li>
              reverse engineer, decompile or disassemble the Software or any
              portion of it.
            </li>
          </AgreementList>
          <li>
            <b>Security Precautions</b>: Tester shall take reasonable security
            precautions to prevent the Software from being seen by unauthorized
            individuals. This includes locking all copies of the Software and
            associated documentation in a desk or file cabinet when not in use.
          </li>
          <li>
            <b>Term of Agreement</b>: The test period shall last from November
            15, 2023, until December 04, 2023. This Agreement shall terminate at
            the end of the test period or when the Company asks Tester to return
            the Software, whichever occurs first. The restrictions and
            obligations contained in Clauses 3, 6, 7, 8 and 9 shall survive the
            expiration, termination or cancellation of this Agreement, and shall
            continue to bind Tester, its successors, heirs and assigns.
          </li>
          <li>
            <b>Return of Software and Materials</b>: Upon the conclusion of the
            testing period or at the Company's request, Tester shall promptly
            (within 10 days) return the original and all copies of the Software
            and all related materials to the Company and erase all portions
            thereof from computer memory.
          </li>
          <li>
            <b>Disclaimer of Warranty</b>: Tester understands and acknowledges
            that the Software is a test product and its accuracy and reliability
            are not guaranteed. Owing to its experimental nature, Tester is
            advised not to rely exclusively on the Software for any reason.
            Tester waives any and all claims it may have against the Company
            arising out of the performance or nonperformance of the Software.
            <br />
            <br />
            THE SOFTWARE IS PROVIDED AS IS, AND THE COMPANY DISCLAIMS ANY AND
            ALL REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
            IMPLIED, WITH RESPECT TO IT, INCLUDING ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
          </li>
          <li>
            <b>Limitation of Liability</b>: The Company shall not be responsible
            for any loss or damage to Tester or any third parties caused by the
            Software or by the Company's performance of this Agreement.
            <br />
            <br />
            THE COMPANY SHALL NOT BE LIABLE FOR ANY DIRECT INDIRECT, SPECIAL,
            INCIDENTAL OR CONSEQUENTIAL DAMAGE, WHETHER BASED ON CONTRACT OR
            TORT OR ANY OTHER LEGAL THEORY, ARISING OUT OF ANY USE OF THE
            SOFTWARE OR ANY PERFORMANCE OF THIS AGREEMENT.
          </li>
          <li>
            <b>No Rights Granted</b>: Tester understands and acknowledges that
            the Software is provided for its own use for testing purposes only.
            This Agreement does not constitute a grant or an intention or
            commitment to grant any right, title or interest in the Software or
            the Company's trade secrets to Tester. Tester may not sell or
            transfer any portion of the Software to any third party or use the
            Software in any manner to produce, market or support its own
            products. Tester shall clearly identify the Software as the
            Company's property.
          </li>
          <li>
            <b>No Assignments</b>: This Agreement is personal to Tester. Tester
            shall not assign or otherwise transfer any rights or obligations
            under this Agreement.
          </li>
          <li>
            <b>Entire Agreement</b>: This Agreement contains the entire
            understanding and agreement of the parties relating to the subject
            matter hereof. Any representation, promise or condition not
            explicitly set forth in this Agreement shall not be binding on
            either party. All additions or modifications to this
          </li>
          <li>
            <b>Applicable Law</b>: This Agreement is made under, and shall be
            construed according to, the laws of the State of New York.
          </li>
        </AgreementList>
      </PageContainer>
    </div>
  );
}
