import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ReactGA from "react-ga4";
import {eventTrigger} from "../../analytics/eventTrigger";

const SIGN_UP_SUCCESS_MESSAGE = 'You successfully signed up.'
const VALIDATE_EMAIL_TIP = 'Check your email and spam folder for the verification link to finalize the sign-up process.';
const INVALID_USER_MESSAGE = 'Your email is not valid. Check your email and spam folder for the verification link to finalize the sign-up process.';

function postLoginApi(callback, username, password, rememberMe, location, onSubmission) {
  axios
      .post(
          `${process.env.REACT_APP_PUBLIC_API_URL}/auth/login`,
          {
            username: username.toLowerCase(),
            password,
            rememberMe: rememberMe === 'true',
            lat: location[1],
            lon: location[0],
          },
          {
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            },
          },
      )
      .then(({ data }) => {
        if (data.success) {
          eventTrigger('Login completed', 'form', 'new_login');
          ReactGA.set({ userId: data.uuid });
        }
        onSubmission(data);
      });
}

function generateInitMessage(queryMessage) {
  switch (queryMessage) {
    case 'signup_success':
      return ({
        type: 'success',
        message: `${SIGN_UP_SUCCESS_MESSAGE} ${VALIDATE_EMAIL_TIP}`,
      });
    case 'signup_oauth_success':
      return ({
        type: 'success',
        message: SIGN_UP_SUCCESS_MESSAGE,
      });
    default:
      return {};
  }
}

function useLoginForm(callback, queryDefaultParams) {
  const [displayValidationMessage, setDisplayValidationMessage] =
      useState(generateInitMessage(queryDefaultParams.get('message')));
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    rememberMe: 'false',
  });

  const ipLocation = () => {
    return new Promise(resolve => {
      const locationApi = axios.get('https://ipapi.co/json').then(response => {
        return [response.data.latitude, response.data.longitude];
      });
      resolve(locationApi);
    });
  };
  const geoLocation = () => {
    return new Promise(resolve => {
      navigator.geolocation.getCurrentPosition(position => {
        const currentLocation = [0.0, 0.0];
        currentLocation[0] = position.coords.latitude;
        currentLocation[1] = position.coords.longitude;
        resolve(currentLocation);
      });
      setTimeout(resolve, 2000);
    });
  };

  const userLocation = async () => {
    const ip = ipLocation();
    const geo = await geoLocation();
    return geo || ip;
  };

  const onSubmission = (data) => {
    setLoading(false);
    if (data.success === true) {
      localStorage.setItem('token', data.token);
      callback(data);
    } else {
      if (data.err === 'The user is not validated') {
        setDisplayValidationMessage({ type: 'error', message: INVALID_USER_MESSAGE })
      }
      toast.error(data.err, {
        position: 'top-center',
      });
    }
  }

  const handleAuthSubmit = async (inputs) => {
    setLoading(true);
    const { username, password } = inputs;
    const location = await userLocation();
    eventTrigger('Login with Auth0');
    postLoginApi(callback, username, password, true, location, onSubmission);
  };

  const handleLoginSubmit = async (event) => {
    if (event) {
      event.preventDefault();
      eventTrigger('Login with form', 'form', 'form_submission');
      setLoading(true);
      event.preventDefault();
      const {username, password, rememberMe} = inputs;
      const location = await userLocation();
      postLoginApi(callback, username, password, rememberMe, location, onSubmission);
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    const newInput = {
      ...inputs,
      [event.target.name]: event.target.checked ? event.target.checked.toString() : event.target.value,
    };
    setInputs(newInput);
  };

  return {
    displayValidationMessage,
    loading,
    handleLoginSubmit,
    handleAuthSubmit,
    handleInputChange,
    inputs,
  };
}

export default useLoginForm;
