function fromApi(data) {
  return {
    id: data.id,
    userId: data.user_id,
    userUuid: data.user_uuid,
    userFirstName: data.first_name,
    userProfilePicture: data.profile_picture,
    isFollowing: data.is_following,
    userLastName: data.surname,
    quantity: data.asset_quantity,
    estimatedCost: data.estimated_cost,
    contractId: data.contract_id,
    description: data.asset_details,
    dateOfCompletion: data.suap_completion_date,
    status: data.status,
    asset: {
      id: data.asset_id,
      uuid: data.asset_uuid,
      title: data.asset_title,
      mainImage: data.asset_main_image,
      images: data.asset_images,
      category: data.asset_category,
      categories: data.asset_categories || [],
      deliveryMethod: data.asset_delivery_method,
      deliveryMethods: data.asset_delivery_methods,
      condition: data.asset_condition,
    },
  };
}

module.exports.fromApi = fromApi;
