import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import { CircularProgress } from "@mui/material";

const EditButtonContainer = styled(IconButton)`
  padding-right: 0;
  ${({ isMobile }) => isMobile && 'padding: 0;'}
  &:hover {
    background-color: unset;
  }
`;

export function EditButton({ onClick, loading, disabled, isMobile, icon: Icon }) {
    return (
        loading ?
        <CircularProgress color="inherit" size={18} /> :
        <EditButtonContainer aria-label="edit-button" disabled={disabled} onClick={onClick} isMobile={isMobile}>
            { Icon ? <Icon /> : <EditIcon /> }
        </EditButtonContainer>
    );
}