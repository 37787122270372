import React, { useState } from 'react';
import styled from 'styled-components';
import Placeholder from '../../../assets/images/placeholderImage.png';
import { Tag } from '../styledComponents';
import { FixBannerPageLayout } from '../../common/Mobile/FixBannerPageLayout';
import { Avatar, Button, Typography } from '@mui/material';
import { styled as styledMUI } from '@mui/material/styles';
import { ContentSection } from '../../common/Mobile/ContentSection';
import { TopCategories } from './components/TopCategories';
import {
  Body10,
  Body8,
  Header18,
  Header20,
  Subheader14,
} from '../../common/Typography';
import useFetchReviews from '../../review/useFetchReviews';
import { NoDataYet, SubHeader } from './styles';
import { THEME_COLORS } from '../../common/colors';
import { getCategoryLabelsFromAssets } from '../../common/utils';
import PlaceIcon from '@mui/icons-material/LocationOnRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useHistory } from 'react-router-dom';
import { ActiveOfferList } from './ActiveOfferList';
import ActionMenuModal from '../../common/Mobile/ActionMenuModal';
import { AssetListMobile } from './components/AssetListMobile';
import IconButton from '@mui/material/IconButton';
import { useIsAlreadyFollowing } from '../../follow/useIsAlreadyFollowing';
import { useFollow } from '../../follow/useFollow';
import { RecentSuaps } from './RecentSuap';
import { useGetFollowingsInfo } from '../../follow/useGetFollowingInfo';
import { FollowersList } from './components/FollowersList';
import { AddNewAssetCard } from '../components/AddNewAssetCard';
import Stack from '@mui/material/Stack';

const Header = styled.div`
  h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 0.5rem;
  }
`;

const Subheader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    text-align: center;
  }
`;

const Location = styled(Body10)`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 0.8rem !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
`;

const CategoryList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const CategoryTag = styled(Tag)`
  background-color: ${THEME_COLORS.primary}20;
  padding: 4px;
  font-size: 0.83rem;
  border-radius: 4px;
  min-width: 10px;
`;

const UserDisplayName = styled(Header20)`
  display: inline-block;
  color: #4e5152 !important;
  line-height: 16px;
`;

const ProfilePicture = styled(Avatar)`
  margin: -52px auto 0 auto !important;
  width: 50% !important;
  margin-bottom: 14px !important;
  height: auto !important;
  border: 4px solid #fff !important;
`;

const ProfileLabel = styled(SubHeader)`
  margin-bottom: 0.6rem !important;
  font-weight: 800 !important;
`;

const EditButton = styledMUI(Button)(({ width }) => ({
  color: THEME_COLORS.primary,
  textTransform: 'capitalize',
  fontWeight: '400',
  minWidth: 25,
  border: `1px solid ${THEME_COLORS.primary}`,
  backgroundColor: '#fff',
  borderRadius: 4,
  width: `${width}%`,
  boxShadow: 'unset',
  '&:hover': {
    backgroundColor: `${THEME_COLORS.subdued}10`,
  },
}));

const PublicButton = styledMUI(Button)(({ width }) => ({
  color: '#fff',
  textTransform: 'capitalize',
  fontWeight: '400',
  minWidth: 25,
  backgroundColor: THEME_COLORS.primary,
  borderRadius: 4,
  width: `${width}%`,
  boxShadow: 'unset',
  '&:hover': {
    backgroundColor: THEME_COLORS.primaryHover,
  },
}));

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const currentFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const AssetSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
`;

const EmptyAsset = styled.div`
  width: 46%;
  height: 56px;
  background-color: #fafafa;
  border-radius: 4px;
`;

const EmptyAssetContainer = styled(Stack)`
  width: 100% !important;
  margin: 4px 8px !important;
`;

function getProfileActions(history, userUuid) {
  return [
    {
      label: 'View public profile',
      action: () => history.push(`/profile/${userUuid}`),
    },
    {
      label: 'View history',
      action: () => null,
      type: 'disabled',
      description: 'Feature coming soon!',
    },
    {
      label: 'Log out',
      type: 'error',
      action: e => {
        e.preventDefault();
        localStorage.removeItem('token');
        window.location = '/?message=logout_success';
      },
    },
  ];
}
export function MobileProfilePage({
  type,
  user = {},
  userLookingForList = [],
  categories = [],
  userAssets = [],
  contractDetails = [],
  suapMetrics = {},
  isCurrentUser,
  userInfo,
}) {
  const history = useHistory();
  const { reviews } = useFetchReviews('user', user.uuid);

  const {
    followersCount,
    followers,
    followingsCount,
    followings,
  } = useGetFollowingsInfo(user.id);
  const [followType, setFollowType] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const {
    isFollowing,
    loading: checking,
    setIsFollowing,
  } = useIsAlreadyFollowing(user.id, isCurrentUser);
  const followCallback = (_, bool) => {
    setIsFollowing(bool);
  };

  const { follow, loading, unfollow } = useFollow(followCallback);

  return (
    <FixBannerPageLayout color="gray" expanded="true">
      <ProfilePicture
        variant="round"
        src={user.profilepicture || Placeholder}
      />
      <Header>
        <UserDisplayName component="h1">
          {user.firstname} {user.surname}
        </UserDisplayName>
      </Header>
      <Location variant="body2" gutterBottom>
        <LocationIcon fontSize="small" />
        {user.displaylocation || 'No Location'}
      </Location>
      <CategoryList>
        {(getCategoryLabelsFromAssets(categories, userAssets) ?? []).map(
          (category, i) => (
            <CategoryTag key={`${category}-${i}`}>
              #{category.toLowerCase()}
            </CategoryTag>
          ),
        )}
      </CategoryList>
      {type === 'view' && (
        <ActionButtonContainer>
          <EditButton width={95} onClick={() => history.push('/profile/edit')}>
            Edit profile
          </EditButton>
          <EditButton width={5} onClick={() => setOpenModal(true)}>
            <MoreVertIcon sx={{ fill: `${THEME_COLORS.primary} !important` }} />
          </EditButton>
        </ActionButtonContainer>
      )}
      {type === 'public' && (
        <ActionButtonContainer>
          {!isFollowing && (
            <PublicButton
              variant="contained"
              width={50}
              disabled={isCurrentUser || checking}
              loading={loading}
              onClick={isCurrentUser ? undefined : () => follow(user.id)}
            >
              Follow
            </PublicButton>
          )}
          {isFollowing && (
            <PublicButton
              variant="contained"
              width={50}
              loading={loading}
              onClick={() => unfollow(user.id)}
            >
              Unfollow
            </PublicButton>
          )}
          <PublicButton
            variant="contained"
            width={50}
            onClick={() => history.push(`/user-suap-wallet/${user.uuid}`)}
          >
            View suap wallet
          </PublicButton>
        </ActionButtonContainer>
      )}
      <Subheader>
        <div>
          <Header18>{reviews.userReviewAverage}</Header18>
          <Subheader14>ratings</Subheader14>
        </div>
        <div onClick={() => setFollowType('Followers')}>
          <Header18>{followersCount || '-'}</Header18>
          <Subheader14>followers</Subheader14>
        </div>
        <div onClick={() => setFollowType('Following')}>
          <Header18>{followingsCount || '-'}</Header18>
          <Subheader14>following</Subheader14>
        </div>
      </Subheader>
      <div style={{ textAlign: 'center', margin: '1rem auto' }}>
        {!!suapMetrics.completedSuapsCount && (
          <Header18>
            {currentFormatter.format(suapMetrics.totalSavings)}
          </Header18>
        )}
        {!suapMetrics.completedSuapsCount && <Header18>-</Header18>}
        <Subheader14>Your savings so far</Subheader14>
      </div>
      <ContentSection>
        <TopCategories
          user={user}
          userLookingForList={userLookingForList ?? []}
        />
      </ContentSection>
      {type === 'view' && (
        <ContentSection>
          <ActiveOfferList
            user={user}
            contractDetails={contractDetails}
            isMobile={true}
          />
        </ContentSection>
      )}
      <ContentSection>
        <ProfileLabel>About {user.firstname}</ProfileLabel>
        <Typography
          sx={{ whiteSpace: 'pre-wrap' }}
          variant="body1"
          gutterBottom
        >
          {user.description || 'No bio yet.'}
        </Typography>
      </ContentSection>
      <ContentSection>
        <MenuContainer>
          <ProfileLabel>Suap wallet</ProfileLabel>
          <IconButton
            onClick={() =>
              history.push(
                type === 'view'
                  ? '/suap-wallet'
                  : `/user-suap-wallet/${user.uuid}`,
              )
            }
            type="button"
            aria-label="active-menu-action"
          >
            <ArrowIcon sx={{ padding: 0, marginBottom: '0.6rem !important' }} />
          </IconButton>
        </MenuContainer>
        {type === 'view' && userAssets.length === 0 && (
          <AssetSection>
            {type === 'newAccount' && (
              <Body8>Products, services and skills I will offer</Body8>
            )}
            <AddNewAssetCard
              hideTitle
              size="mobile-thumbnail"
              openAssetForm={() => openAssetForm(null)}
            />
            <EmptyAssetContainer
              spacing={1}
              direction="row"
              useFlexGap
              flexWrap="wrap"
            >
              <EmptyAsset />
              <EmptyAsset />
              <EmptyAsset />
              <EmptyAsset />
            </EmptyAssetContainer>
          </AssetSection>
        )}
        {userAssets?.length !== 0 && (
          <AssetListMobile userAssets={userAssets} />
        )}
      </ContentSection>
      <ContentSection>
        <div>
          <ProfileLabel>When I suap, I value</ProfileLabel>
          <div>
            {user.profilevalues?.length > 0 ? (
              user.profilevalues?.map(value => {
                return <Tag key={`${value}-tag`}>{value}</Tag>;
              })
            ) : (
              <NoDataYet>No values added yet</NoDataYet>
            )}
          </div>
        </div>
      </ContentSection>
      {type === 'public' && (
        <ContentSection>
          <ProfileLabel>Recent Suaps</ProfileLabel>
          {contractDetails?.length > 0 ? (
            <RecentSuaps
              recentSuaps={contractDetails}
              user={user}
              isMobile={true}
            />
          ) : (
            'No recent suaps yet.'
          )}
        </ContentSection>
      )}
      <FollowersList
        followingContent={
          followType === 'Followers'
            ? followers
            : followType === 'Following'
            ? followings
            : []
        }
        title={followType}
        isOpen={!!followType}
        follow={follow}
        unfollow={unfollow}
        onClose={() => setFollowType(null)}
        userInfo={userInfo}
        isCurrentUser={isCurrentUser}
        isMobile={true}
      />
      {openModal && (
        <ActionMenuModal
          options={getProfileActions(history, user.uuid)}
          onClose={() => setOpenModal(false)}
        />
      )}
    </FixBannerPageLayout>
  );
}
