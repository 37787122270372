import React from 'react';
import { AssetList, PanelHeader, Thumbnail } from '../styledComponents';
import { Typography } from '@mui/material';
import { NoDataYet, SubHeader, Tag } from './styles';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { RecentSuaps } from './RecentSuap';
import { AddNewAssetCard } from '../components/AddNewAssetCard';

const RightPanel = styled.div`
  grid-area: RightPanel;
  display: flex;
  flex-direction: column;
  background-color: #f4f5f4;
  padding: 0 2rem;
`;

const ProfileLabel = styled(SubHeader)`
  margin-top: 2.6rem !important;
  margin-bottom: 1rem !important;
  font-weight: 800 !important;
  text-align: center;
`;

const NoDataTip = styled(NoDataYet)`
  text-align: center;
`;

export function RightSection({
  contractDetails,
  type,
  user,
  userAssets,
  openAssetForm,
}) {
  const history = useHistory();

  return (
    <RightPanel>
      {userAssets.length > 0 && (
        <AssetList>
          {userAssets.map((asset, i) => (
            <div
              key={`asset-list-${asset.category}-${i}`}
              style={{ margin: 4, maxWidth: 80, cursor: 'pointer' }}
              onClick={() => history.push(`/assets/${asset.uuid}`)}
            >
              <Thumbnail style={{ margin: '8px auto', width: 75 }}>
                <img alt="placeholder" src={asset.mainImage} />
              </Thumbnail>
              <Typography variant="body2">{asset.title}</Typography>
            </div>
          ))}
        </AssetList>
      )}
      {type === 'view' && userAssets.length === 0 && (
        <AddNewAssetCard openAssetForm={() => openAssetForm()} />
      )}
      {type === 'view' && (
        <div>
          <ProfileLabel>When I suap, I value</ProfileLabel>
          <div>
            {user.profilevalues.length > 0 ? (
              user.profilevalues.map(value => {
                return <Tag key={`${value}-tag`}>{value}</Tag>;
              })
            ) : (
              <NoDataTip>No values added yet</NoDataTip>
            )}
          </div>
        </div>
      )}
      {type === 'public' && user.show_savings && (
        <div>
          <ProfileLabel>Recent Suaps</ProfileLabel>
          {contractDetails?.length > 0 ? (
            <RecentSuaps recentSuaps={contractDetails} user={user} />
          ) : (
            'No recent suaps yet.'
          )}
        </div>
      )}
    </RightPanel>
  );
}
