import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga4';

if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    console.log('Google analytics enabled for env ', process.env.REACT_APP_ENVIRONMENT)
    ReactGA.initialize('G-WLG2BNZJF0');
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
