import React, { useState } from 'react';
import Searchbar from '../../common/Searchbar';
import { HowToSuapModal } from '../../common/HowToSuapModal';
import AssetCard from '../components/AssetCard';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import PlaceIcon from '@mui/icons-material/LocationOnRounded';

import { CategoryImageList } from './CategoryImageList';
import { getCategoryLabelsFromValues } from '../../common/utils';
import { FILTERED_CONDITION_OPTIONS } from '../constants';
import { DELIVERY_OPTIONS } from '../../common/constants';
import Chip from '@mui/material/Chip';
import useGetCategories from '../../common/hooks/useGetCategories';
import Stack from '@mui/material/Stack';
import SecondaryGrayButton from '../../common/SecondaryGrayButton';
import { NoDataContainer } from '../../common/NoDataContainer';
import { Subheader18 } from '../../common/Typography';

const StackTags = styled(Stack)`
  margin-top: 1rem !important;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`;

const Location = styled(Typography)`
  display: flex !important;
  align-items: center !important;
  ${({ isMobile }) => isMobile && 'justify-content: center !important;'}
  margin-bottom: 1rem !important;
`;

const LocationIcon = styled(PlaceIcon)`
  fill: #717273 !important;
`;

const AssetList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, ${({ isMobile }) =>
    isMobile ? 250 : 150}px);

  @media screen and (min-width: 375px) {
    grid-template-columns: repeat(auto-fit, 165px);
  }

  @media screen and (min-width: 440px) {
    grid-template-columns: repeat(auto-fit, 200px);
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, 238px);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(auto-fit, 220px);
  }

  @media screen and (min-width: 1440px) {
     grid-template-columns: repeat(auto-fit, 200px);
  }

  ${({ isMobile }) => isMobile && 'grid-gap: 0.5rem;'}
  ${({ isMobile }) => !isMobile && 'row-gap: 1rem;'}
  justify-content: ${({ wrapElements }) =>
    wrapElements ? 'space-between' : 'flex-start'};
  justify-content: ${({ isMobile }) => isMobile && 'center'};
  align-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
`;

const AssetListContainer = styled.div`
  max-width: 834px;
  margin: 0 auto;
  ${({ isMobile }) => isMobile && 'margin: 0 -1.5rem 0 0;'}
`;

const Header = styled(Subheader18)`
  margin-bottom: 2rem !important;
  text-align: center;
`;

function AssetSearchResults({ isMobile, user, assets }) {
  const history = useHistory();

  return (
    <AssetListContainer isMobile={isMobile}>
      <Header isMobile={isMobile}>
        Check out these finds, tailored just for you. Suap now!
      </Header>
      <AssetList wrapElements={assets.length > 4} isMobile={isMobile}>
        {assets.map(asset => (
          <AssetCard
            isMobile={isMobile}
            key={`asset-${asset.uuid}`}
            asset={asset}
            history={history}
            userInfo={user}
          />
        ))}
      </AssetList>
    </AssetListContainer>
  );
}

function FilterList({ filters, onFilterChange }) {
  const { categories } = useGetCategories(true);
  const categoryOptions = getCategoryLabelsFromValues(
    categories,
    filters.category,
  );
  const conditionOptions = FILTERED_CONDITION_OPTIONS.filter(({ value }) =>
    filters.level.includes(value.toString()),
  ).map(({ label }) => label);
  const deliveryOptions = DELIVERY_OPTIONS.filter(({ value }) =>
    filters.delivery_method.includes(value),
  ).map(({ label }) => label);

  return (
    <StackTags direction="row" spacing={1}>
      {[...categoryOptions, ...conditionOptions, ...deliveryOptions].map(
        option => (
          <Chip label={option} size="small" />
        ),
      )}
    </StackTags>
  );
}
export function MiddleSection({
  isMobile,
  isProfileCompleted,
  assets,
  assetLoaded,
  filters,
  user,
  onFilterChange,
  onSearchChange,
  categories,
  setHideAdvertisement,
  clearFilterAndSearch,
}) {
  const dismissedAutoHowToPopup = localStorage.getItem(
    'dismissedAutoHowToPopup',
  );
  const [howToOpen, setHowToOpen] = useState(
    !isProfileCompleted && !dismissedAutoHowToPopup,
  );

  return (
    <div>
      <div style={{ margin: `0 auto` }}>
        {user.displaylocation && (
          <Location variant="body2" gutterBottom isMobile={isMobile}>
            <LocationIcon fontSize="small" />
            {user.displaylocation || 'No Location'}
          </Location>
        )}
        <div style={{ marginBottom: '2rem' }}>
          <div
            style={{
              display: isMobile ? 'block' : 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <div
              style={{
                width: isMobile ? '100%' : '40%',
                maxWidth: 550,
                marginBottom: '1rem',
              }}
            >
              <Searchbar showResults onSearch={onSearchChange} />
            </div>
            <HowToSuapModal
              isMobile={isMobile}
              isOpen={howToOpen}
              setOpen={setHowToOpen}
              position="relative"
            />
          </div>
          <FilterList filters={filters} onFilterChange={onFilterChange} />
        </div>
      </div>
      {!assetLoaded && (
        <CategoryImageList
          isMobile={isMobile}
          user={user}
          categories={categories}
          onFilterChange={onFilterChange}
          setHideAdvertisement={setHideAdvertisement}
        />
      )}
      {assetLoaded && (
        <AssetSearchResults user={user} assets={assets} isMobile={isMobile} />
      )}
      {assetLoaded && assets.length === 0 && (
        <NoDataContainer
          message="Help us create something great! Invite friends or explore other categories to find what you're looking for."
          fontSize={!isMobile ? 2 : 1.2}
          size={300}
          actionButton={
            !isMobile ? (
              <SecondaryGrayButton onClick={clearFilterAndSearch}>
                Go back
              </SecondaryGrayButton>
            ) : (
              undefined
            )
          }
        />
      )}
    </div>
  );
}
