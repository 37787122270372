import React from 'react';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import DescriptiveRating from '../../assets/components/DescriptiveRating';
import {
  getDeliveryMethodFromValue,
  getDeliveryMethodsFromValue,
} from '../../common/utils';
import styled from 'styled-components';
import Placeholder from '../../../assets/images/placeholderImage.png';
import moment from 'moment';
import DialogForm from '../../common/DialogForm';
import useCreateOrUpdateContract from './useCreateOrUpdateContract';
import {
  predefineDeniedSuap,
  predefineFirstApproves,
  predefineSecondApproves,
} from '../predefinedMessages';
import { Body10, Body8, Header12, Header18 } from '../../common/Typography';
import { THEME_COLORS } from '../../common/colors';
import DialogContent from '@mui/material/DialogContent';
import { styled as styledMUI } from '@mui/material/styles';
import FORMAT_SIZES from '../../common/constants/FormatSizes';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ReactSVG } from 'react-svg';
import Arrow from '../../../assets/arrow.svg';

const DetailField = styled.div`
  max-width: 240px;
  margin-bottom: 0.5rem;
`;

const Description = styled.div`
  margin: 0 -1rem;
  text-align: ${({ align }) => align ?? 'left'};
`;

const SuapIcon = styled(ReactSVG)`
  svg {
    height: ${({ isMobile }) => (isMobile ? 30 : 50)}px !important;
  }
  display: block !important;
  fill: ${THEME_COLORS.primary}!important;
  ${({ flipped }) =>
    flipped &&
    `
    transform: scale(-1, -1) !important;
  `}
`;

const TermsTitle = styled(Header18)`
  text-align: center !important;
`;

const SuapTermContainer = styled.div`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? 0 : 12)}px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

const AssetPanel = styledMUI(Box)(({ isMobile }) => ({
  flexDirection: `${isMobile ? 'row' : 'column'} !important`,
  gap: `${isMobile ? 12 : 0}px !important`,
  textAlign: `${isMobile ? 'left' : 'center'} !important`,
  ...(isMobile ? { width: '100%', maxWidth: '240px' } : {}),
  justifyContent: 'center !important',
  alignItems: 'center !important',
}));

const Dialog = styledMUI(DialogContent)(({ isMobile }) => ({
  '&.MuiDialogContent-root:first-child': {
    paddingTop: isMobile ? 0 : '20px',
  },
}));

const SmallBoldHeader = styled(Body10)`
  font-weight: 700 !important;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
`;

const InlineContainer = styled.div`
  display: flex;
  justify-content: ${({ align }) => align ?? 'center'};
  align-items: center;
  margin-bottom: 0.3rem !important;
`;

const InlineSmallBoldText = styled(Body8)`
  font-weight: 700 !important;
  margin-right: 0.5rem !important;
`;

const AssetMainImage = styled(Avatar)`
  width: ${({ isMobile }) => (isMobile ? 50 : 100)}% !important;
  max-width: 100px !important;
  height: auto !important;
  margin-bottom: 0.7rem !important;
`;

function TruncateDescription({ children }) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (children?.length > 100) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            title={children}
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
          >
            <Body10 onClick={handleTooltipOpen}>
              {children.slice(0, 100)}[...]
            </Body10>
          </Tooltip>
        </div>
      </ClickAwayListener>
    );
  }

  return <Body10>{children}</Body10>;
}

function UserContractViewMobile({ format, user, contract }) {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <AssetPanel
        sx={{ display: { xs: 'flex', sm: 'inline-flex' } }}
        isMobile={format === FORMAT_SIZES.MOBILE}
      >
        <AssetMainImage
          variant="round"
          src={contract.asset?.mainImage || Placeholder}
          isMobile={true}
        />
        <DetailField>
          <InlineContainer align="flex-start">
            <InlineSmallBoldText>by</InlineSmallBoldText>
            <Typography>
              {moment(contract.dateOfCompletion).format('MM/DD/YYYY')}
            </Typography>
          </InlineContainer>
          <InlineContainer align="flex-start">
            <InlineSmallBoldText>via</InlineSmallBoldText>
            <Typography>
              {getDeliveryMethodsFromValue(contract.asset?.deliveryMethods) ||
                getDeliveryMethodFromValue(contract.asset?.deliveryMethod)}
            </Typography>
          </InlineContainer>
        </DetailField>
      </AssetPanel>
      <Description align="center">
        <DetailField>
          <Header12>{contract.asset?.title}</Header12>
        </DetailField>
        <DetailField>
          <DescriptiveRating
            categoryType={contract.asset?.type}
            value={contract.asset?.condition ?? 1}
            readOnly
          />
        </DetailField>
        <SmallBoldHeader>
          {user.firstName} {user.lastName}
        </SmallBoldHeader>
        <DetailField>
          <TruncateDescription>{contract.description}</TruncateDescription>
        </DetailField>
      </Description>
    </Box>
  );
}

function UserContractView({ format, user, contract, isMobile }) {
  if (format === FORMAT_SIZES.WEB) {
    return (
      <AssetPanel
        sx={{ display: 'inline-flex', width: '35%', padding: '1rem' }}
      >
        <AssetMainImage
          variant="round"
          src={contract.asset?.mainImage || Placeholder}
          isMobile={isMobile}
        />
        <Description align="center">
          <DetailField>
            <Header12>{contract.asset?.title}</Header12>
          </DetailField>
          <DetailField>
            <DescriptiveRating
              categoryType={contract.asset?.type}
              value={contract.asset?.condition ?? 1}
              readOnly
            />
          </DetailField>
          <SmallBoldHeader>
            {user.firstName} {user.lastName}
          </SmallBoldHeader>
          <DetailField>
            <TruncateDescription>{contract.description}</TruncateDescription>
          </DetailField>
        </Description>
      </AssetPanel>
    );
  }

  return (
    <UserContractViewMobile format={format} user={user} contract={contract} />
  );
}

export default function ContractView({
  format,
  currentUser = {},
  otherUser = {},
  contractUser = {},
  otherContractUser = {},
  setOpenContractModal,
  onUpdateSuapContract,
  isMobile,
  isOpen,
}) {
  const { updateContract } = useCreateOrUpdateContract(
    setOpenContractModal,
    onUpdateSuapContract,
  );
  const pendingApproval = otherContractUser.status !== 'APPROVED';

  const handleContractApproval = () => {
    const message =
      contractUser.status !== 'APPROVED'
        ? predefineFirstApproves(otherUser.firstName, currentUser.firstName)
        : predefineSecondApproves(otherUser.firstName, currentUser.firstName);
    updateContract(
      {
        ...otherContractUser,
        status: 'APPROVED',
      },
      message,
    );
  };

  const handleContractUnapproval = () => {
    if (pendingApproval) {
      const message = predefineDeniedSuap(
        otherUser.firstName,
        currentUser.firstName,
      );
      onUpdateSuapContract(message);
    }
    setOpenContractModal(null);
  };

  return (
    <DialogForm
      label=""
      description=""
      closeLabel={pendingApproval ? 'No, decline' : ''}
      buttonLabel="Yes, suap"
      onSave={pendingApproval && handleContractApproval}
      onClose={() => setOpenContractModal(null)}
      onSecondaryAction={handleContractUnapproval}
      isMobile={isMobile}
      isOpen={isOpen}
    >
      <Dialog isMobile={isMobile}>
        <TermsTitle>
          {pendingApproval ? 'Ready to suap?' : 'WeSuap Terms'}
        </TermsTitle>
        <SuapTermContainer isMobile={format === FORMAT_SIZES.MOBILE}>
          <UserContractView
            user={otherUser}
            contract={otherContractUser}
            isMobile={isMobile}
            format={format}
          />
          <AssetPanel
            sx={{
              display: {
                xs: 'flex',
                sm: 'inline-flex',
                md: 'inline-flex',
                lg: 'inline-flex',
              },
            }}
          >
            <SuapIcon isMobile={isMobile} src={Arrow} />
            <SuapIcon flipped={true} isMobile={isMobile} src={Arrow} />
          </AssetPanel>
          <UserContractView
            user={currentUser}
            contract={contractUser}
            isMobile={isMobile}
            format={format}
          />
        </SuapTermContainer>
      </Dialog>
    </DialogForm>
  );
}
