import DialogForm from './DialogForm';
import styled from 'styled-components';
import { THEME_COLORS } from './colors';
import Fab from '@mui/material/Fab';

const positionStyle = {
  fixed: {
    position: 'fixed',
    bottom: 12,
    right: 12,
  },
  fixedMobile: {
    position: 'fixed',
    bottom: 72,
    right: 12,
  },
  relative: {
    position: 'relative',
  },
};
const InstructionContainer = styled.div`
  padding: 24px 36px 0 36px;
  overflow-y: hidden;
`;
const Section = styled.div`
  display: flex;
  margin: 24px;
`;
const Title = styled.h1`
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: 24px;
  margin-bottom: 36px;
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 1.3rem;
`;
const InstructionNumber = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  font-size: 1.2rem;
  margin-right: 12px;
  background-color: ${THEME_COLORS.primary};
  color: #fff;
`;

const Description = styled.div`
  width: 90%;
`;

const HowItWorksButton = styled(Fab)`
  box-shadow: unset !important;
  height: 24px !important;
  min-width: 116px !important;
`;

export function HowToSuapModal({ isMobile, isOpen, setOpen, position }) {
  return (
    <>
      <HowItWorksButton
        variant="extended"
        sx={{
          ...positionStyle[position ?? 'fixed'],
          fontWeight: 'bold',
          textTransform: 'capitalize',
        }}
        size="small"
        onClick={() => setOpen(true)}
      >
        🚀 How it works
      </HowItWorksButton>
      {isOpen && (
        <DialogForm
          theme="subdued"
          closePosition="right"
          isMobile={isMobile}
          radius={0}
          width={525}
          closeLabel="Ok, got it!"
          onCancel={() => {
            setOpen(false);
            localStorage.setItem('dismissedAutoHowToPopup', 'true');
          }}
        >
          <InstructionContainer>
            <Title>🚀 How it works:</Title>
            <Section>
              <InstructionNumber>1</InstructionNumber>
              <Description>
                <Header>Complete your profile</Header>
                <p>Located in our main menu. Trust is key.</p>
              </Description>
            </Section>
            <Section>
              <InstructionNumber>2</InstructionNumber>
              <Description>
                <Header>Add your assets</Header>
                <p>
                  Think of products, services, or skills you can exchange for
                  the things you want. Only after this step you will be able to
                  suap.
                </p>
              </Description>
            </Section>
            <Section>
              <InstructionNumber>3</InstructionNumber>
              <Description>
                <Header>Start Suaping</Header>
                <p>
                  Consent matters. Once you find what you want, you will be able
                  to discuss and agree on your preferred suaping terms like
                  dates and details.
                </p>
              </Description>
            </Section>
          </InstructionContainer>
        </DialogForm>
      )}
    </>
  );
}
