import React from 'react';
import styled from 'styled-components';
import { THEME_COLORS } from '../../common/colors';
import Link from '@material-ui/core/Link';
import { Avatar } from '@mui/material';
import { ContentSection } from '../../common/Mobile/ContentSection';
import { PostActionBar } from './PostActionBar';
import { Comments } from '../comments';

const UserProfile = styled(Avatar)`
  width: ${({ isMobile }) => (isMobile ? 42 : 54)}px !important;
  height: ${({ isMobile }) => (isMobile ? 42 : 54)}px !important;
  margin-right: 1rem !important;
`;

const SuapContainer = styled.div`
  ${({ isMobile }) =>
    !isMobile &&
    `
    margin-top: 12px;
    padding: 12px 0;
  `}
  display: flex;
  align-items: center;
  background-color: #fff;
`;

const SuapDetail = styled.div`
  display: block;
  font-size: 1rem;
`;

const SuapDetails = styled.span`
  color: ${THEME_COLORS.primary} !important;
  font-style: italic !important;
`;

const HorizontalSpacing = styled.div`
  display: inline-block;
  width: ${({ spacing }) => spacing ?? 2}px;
`;

const MinimalLink = styled(Link)`
  font-weight: 700 !important;
`;

const PostContainer = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid #f4f5f4;
`;

export function CompletedSuapPost({
  isMobile,
  userContract,
  post,
  isPreview,
  onShowComments,
}) {
  const userSuap =
    userContract.userId === post.requestedUserContract.userId
      ? post.requestedUserContract
      : post.requesterUserContract;
  const otherUserSuap =
    userContract.userId === post.requestedUserContract.userId
      ? post.requesterUserContract
      : post.requestedUserContract;
  const { asset: userAsset, quantity: userQuantity } = userSuap;
  const { asset, quantity } = otherUserSuap;
  const Component = isMobile ? ContentSection : PostContainer;

  return (
    <Component>
      <SuapContainer isMobile={isMobile}>
        <UserProfile
          alt="user-profile-picture"
          src={userSuap.userProfilePicture}
          isMobile={isMobile}
        />
        <SuapDetail>
          <MinimalLink href={`/profile/${userContract.userId}`}>
            <b>
              {userContract.userFirstName} {userContract.userLastName}
            </b>
          </MinimalLink>
          <HorizontalSpacing spacing={4} />
          just suaped
          <HorizontalSpacing spacing={4} />
          <MinimalLink href={`/assets/${userAsset.id}`}>
            <SuapDetails>
              {userQuantity} {userAsset.title}
            </SuapDetails>
          </MinimalLink>
          <HorizontalSpacing spacing={4} />
          for a
          <HorizontalSpacing spacing={4} />
          <MinimalLink href={`/assets/${asset.id}`}>
            <SuapDetails>
              {quantity} {asset.title}
            </SuapDetails>
          </MinimalLink>
        </SuapDetail>
      </SuapContainer>
      {!isPreview && (
        <>
          <PostActionBar
            postId={post.id}
            type="CONTRACT"
            currentLikeNum={post.likesCount}
            likeId={post.likeId}
            onShowComments={onShowComments}
          />
          <Comments
            isMobile={isMobile}
            type="CONTRACT"
            postId={post.id}
            commentInitNum={post.commentsCount}
            onShowComments={onShowComments}
          />
        </>
      )}
    </Component>
  );
}
