import React, { useState } from "react";
import styled from "styled-components";
import ActionMenuModal from "../../../common/Mobile/ActionMenuModal";
import { useHistory } from "react-router-dom";

const THUMBNAIL_WIDTH = 60;
const THUMBNAIL_HEIGHT = 65;

const THUMBNAIL_WIDTH_MD = 70;
const THUMBNAIL_HEIGHT_MD = 75;

const THUMBNAIL_WIDTH_LG = 80;
const THUMBNAIL_HEIGHT_LG = 85;


const MainAsset = styled.div`
  width: 100px;
  height: 120px;
  display: flex;

  @media screen and (min-width: 375px) {
    width: 120px;
    height: 140px;
  }

  @media screen and (min-width: 425px) {
    width: 140px;
    height: 160px;
  }
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 125%;
    height: auto;
  }
`;

const Thumbnail = styled.div`
  width: ${THUMBNAIL_WIDTH}px;
  height: ${THUMBNAIL_HEIGHT}px;

  @media screen and (min-width: 375px) {
    width: ${THUMBNAIL_WIDTH_MD}px;
    height: ${THUMBNAIL_HEIGHT_MD}px;
  }

  @media screen and (min-width: 425px) {
    width: ${THUMBNAIL_WIDTH_LG}px;
    height: ${THUMBNAIL_HEIGHT_LG}px;
  }

  display: flex;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 125%;
    height: auto;
  }
`;

const AssetListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 54%;
  gap: 6px;
`;

const AssetSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmptyAsset = styled.div`
  width: ${THUMBNAIL_WIDTH}px;
  height: ${THUMBNAIL_HEIGHT}px;

  @media screen and (min-width: 375px) {
    width: ${THUMBNAIL_WIDTH_MD}px;
    height: ${THUMBNAIL_HEIGHT_MD}px;
  }

  @media screen and (min-width: 425px) {
    width: ${THUMBNAIL_WIDTH_LG}px;
    height: ${THUMBNAIL_HEIGHT_LG}px;
  }

  background-color: #F4F5F4;
  border-radius: 8px;
`;

function getMenuActions(history, assetId) {
    return [{
        label: 'View asset details',
        action: () => history.push(`/assets/${assetId}`),
    },
    {
        label: 'Like',
        action: () => null,
        type: 'disabled',
        description: 'Feature coming soon!'
    }]
}

export function AssetListMobile({ userAssets = [] }) {
    const history = useHistory();
    const assets = (userAssets ?? []).slice(0, 5);
    const [mainAsset, ...thumbnailAssets] = assets ?? [];
    const [openModal, setOpenModal] = useState(false);
    const [menuActions, setMenuActions] = useState([]);

    const handleAssetSelect = (id) => () => {
        setMenuActions(getMenuActions(history, id));
        setOpenModal(true);
    }

    return (
        <div>
            <AssetSection>
                <MainAsset onClick={handleAssetSelect(mainAsset?.uuid)}>
                    <img alt="placeholder" src={mainAsset?.mainImage} />
                </MainAsset>
                <AssetListContainer>
                    {
                        (thumbnailAssets ?? []).map((asset, i) => (
                            <Thumbnail
                                key={`asset-list-${asset.category}-${i}`}
                                onClick={handleAssetSelect(asset.uuid)}
                            >
                                <img alt="placeholder" src={asset.mainImage}/>
                            </Thumbnail>
                        ))
                    }
                    {
                        (thumbnailAssets ?? []).length < 4 &&
                        new Array(4 - (thumbnailAssets ?? []).length)
                            .fill(null)
                            .map((_, i) => (
                                <EmptyAsset key={`asset-list-${i}`} />
                        ))
                    }
                </AssetListContainer>
            </AssetSection>
            {
                openModal &&
                <ActionMenuModal
                    options={menuActions}
                    onClose={() => setOpenModal(false)}
                />
            }
        </div>
    );
}