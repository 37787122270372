import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';

function fromApi(data) {
  return {
    id: data.id,
    uuid: data.uuid,
    userId: data.user_id,
    title: data.title ?? '',
    description: data.description ?? '',
    images: data.images || [],
    categories: data.categories || [],
    condition: Number(data.level ?? 0),
    type: data.type ?? 'PRODUCT',
    category: data.category ?? '',
    mostLikelySuap: data.most_likely_suap ?? [],
    mainImage: data.main_image ?? '',
    customizable: !!data.customizable,
    deliveryMethod: data.delivery_method ?? '',
    deliveryMethods:
      data.delivery_methods ??
      (data.delivery_method ? [data.delivery_method] : []),
    estimatedCost: data.estimate_cost ?? '',
    serialId: data.serial_id,
    user: {
      id: data.user_id,
      uuid: data.user_uuid,
      profilePicture: data.profilepicture ?? '',
      firstName: capitalizeFirstLetter(data.firstname ?? ''),
      lastName: capitalizeFirstLetter(data.surname ?? ''),
      location: data.location ?? [],
      displaylocation: data.displaylocation ?? '',
      topCategories: data.topcategories ?? [],
    },
  };
}

export { fromApi };
